import Parse from 'parse';
import useDateHelper from '../../composables/useDateHelper.js';
import { getConfirmRequestsByRequestDate } from '@/services/confirmRequestService';

const VacationRequest = Parse.Object.extend('VacationRequest');

const defaultTemporaryState = () => {
  return {
    requestFrom: '',
    requestTo: '',
    status: 'open',
    days: [],
    user: Parse.User.current(),
  };
};

const VacationRequestModule = {
  namespaced: true,
  state: {
    temporaryRequest: defaultTemporaryState,
    requests: [],
    requestStatus: [],
    limit: 3000,
    lastFetched: null,
    requestsToConfirm: [],
  },
  mutations: {
    addTemporaryRequest(state, request) {
      state.temporaryRequest.requestFrom = request.requestFrom;
      state.temporaryRequest.requestTo = request.requestTo;
      state.temporaryRequest.days = request.days;
      state.temporaryRequest.value = request.value;
    },
    addRequest(state, request) {
      state.requests.push(
        request
      );
    },
    removeRequest(state, { request }) {
      state.requests.splice(state.requests.indexOf(request), 1);
    },
    resetTemporaryRequest(state) {
      Object.assign(state.temporaryRequest, defaultTemporaryState());
    },
    setTemporaryRequest(state, request) {
      state.temporaryRequest = request;
    },
    setRequests(state, requests) {
      state.requests = requests;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
    setRequestsToConfirm(state, request) {
      state.requestsToConfirm = request;
    },
    setRequestStatus(state, requestStatus) {
      state.requestStatus = requestStatus;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async loadTemporaryRequest({}) {},
    async loadRequests({ commit, state }, user = Parse.User.current()) {
      try {
        const query = new Parse.Query('VacationRequest');
        query.limit(state.limit);
        query.equalTo('user', user);
        query.addDescending('createdAt');
        const requests = await query.find(null);
        commit('setLastFetch', new Date());
        commit('setRequests', requests);
      } catch (e) {
        commit('setRequests', []);
      }
    },
    async loadAllRequests({ commit, state }) {
      try {
        const query = new Parse.Query('VacationRequest');
        query.limit(state.limit);
        query.addDescending('createdAt');
        query.include('user');
        const requests = await query.find(null);
        commit('setLastFetch', new Date());
        commit('setRequests', requests);
      } catch (e) {
        commit('setRequests', []);
      }
    },
    async loadRequestStatus({ commit }, user = Parse.User.current()) {
      const vQuery = new Parse.Query('VacationConfirmRequest');
      vQuery.equalTo('user', user);
      vQuery.include('chef');
      const requestStatus = await vQuery.find(null);
      commit('setRequestStatus', requestStatus);
    },
    resetTemporaryRequestState({ commit }) {
      commit('resetTemporaryRequest');
    },
    async loadRequestsToConfirm({ commit }) {
      try {
        const query = new Parse.Query('VacationConfirmRequest');
        query.include('request');
        query.include('user');
        query.equalTo('chef', Parse.User.current());
        query.equalTo('status', 'open');

        const requests = await query.find();
        commit('setRequestsToConfirm', requests);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    async addVacationRequest({ commit }, request) {
      let vacationRequest = new VacationRequest();
      vacationRequest.set('requestFrom', new Date(request.requestFrom));
      vacationRequest.set('requestTo', new Date(request.requestTo));
      vacationRequest.set('status', 'open');
      vacationRequest.set('days', request.days);
      vacationRequest.set('user', Parse.User.current());
      try {
        vacationRequest = await vacationRequest.save();
        if (request.user === Parse.User.current()) {
          commit('addRequest', vacationRequest);
        }
        return vacationRequest;
      } catch (error) {
        console.log(error);
        return {
          errorMsg: 'Bereits Urlaub in diesem Zeitraum beantragt',
        };
        // eslint-disable-next-line
      }
    },
  },
  getters: {
    temporaryRequest: (state) => state.temporaryRequest,
    requests: (state) => state.requests,
    requestsToConfirm: (state) => state.requestsToConfirm,
    getRequestsStartingSoonToConfirm: (state) => (weeks) =>
      getConfirmRequestsByRequestDate(state.requestsToConfirm, useDateHelper.getDateFromNowAddWeeks(weeks)),
    requestStatus: (state) => state.requestStatus,
  },
};
export default VacationRequestModule;
