export default {
  accept: 'Akzeptieren',
  accepted_from: 'Angenommen von',
  add_calendar_event: 'Event hinzufügen',
  add_event_for_user: 'Event für User erstellen',
  create_links: 'Links zur Linksammlung erstellen',
  analytics: 'Analytik',
  manage_link: 'Links-Manager',
  calendar: 'Kalender',
  calendar_events: 'Kalenderereignisse',
  close: 'Schließen',
  comment: 'Kommentieren',
  add_folder: 'Ordner hinzufügen',
  new_folder_name: 'Neuer Ordnername',
  create_new_folder: 'Neuen Ordner erstellen',
  search: 'Suche...',
  create_docPool: 'Anlegen',
  upload_file: 'Datei hochladen',
  upload_files: 'Dateien hochladen',
  file_download: 'Datei herunterladen',
  search_filter: 'Suchen',
  create_new_comment: 'Einen Kommentar verfassen ...',
  days: 'Tage',
  days_left: 'Tage verfügbar',
  days_taken: 'Tage genommen',
  decline: 'Ablehnen',
  quarantine: 'Quarantäne',
  declined_from: 'Abgelehnt von',
  library: 'Linksammlung',
  delete: 'Löschen',
  confirmed_by: 'Bestätigt von',
  personal_details: 'Persönliche Information',
  firstname: 'Vorname:',
  lastname: 'Nachname:',
  employee_nr: 'Mitarbeiternr.:',
  employee_number: 'Mitarbeiternummer',
  edit: 'Bearbeiten',
  position: 'Position:',
  vac_days: 'Urlaubstage',
  vac_type: 'Urlaubstyp',
  username_1: 'Username:',
  groups_1: 'Gruppen:',
  default_vac_days: 'Standard-Urlaubstage:',
  vac_amount: 'Anzahl der Urlaubstage',
  change_password: 'Passwort ändern',
  social_groups: 'Social Gruppen',
  start_day_1: 'Eintrittsdatum:',
  social_business_color: 'Social Business Farbe',
  special_vac: 'Sonderurlaub',
  create: 'Anlegen',
  amount_days: 'Anzahl Tage',
  start_day:
    'Beginn (Anzahl der Tage werden ab dem Startdatum berechnet, Wochenenden und Feiertage werden nicht berücksichtigt.)',
  edit_small: 'bearbeiten',
  choose_deputy: 'Vertreter auswählen',
  deputy_ruling: 'Vertreterregelung',
  chosen_deputy: 'Aktuell als Vertreter ausgewählt:',
  deputy_gets_all_vac_requests: 'Vertreter bekommt im Falle von Urlaub des Chefs alle Urlaubsanfragen',
  default_deputy: 'Standartvertreter auswählen',
  default_deputy_atm: 'Aktuell als Standartvertreter ausgewählt:',
  default_deputy_when_chef_vac:
    'Standartvertreter wird im Falle von Urlaub des Chefs als Vertreter eingesetzt, wenn kein\n' +
    '        Vertreter gesetzt ist.',
  education_company: 'Überbetriebliche Ausbildung',
  education_vacation: 'Weiterbildungsurlaub',
  add_comment: 'Kommentar hinzufügen...',
  final_comment: 'Abschließen',
  phone_number: 'Telefonnummer',
  email: 'E-Mail',
  employee: 'Mitarbeiter',
  employees_on_vacation: 'Arbeitnehmer im Urlaub',
  end: 'Ende',
  error_max_file_upload_size: 'Datei muss kleiner als {data} sein.',
  error_mime_type_not_allowed: 'Datei muss von folgendem typen sein {data}.',
  feessbook: 'Feessbook',
  flex_time: 'Flexzeitausgleich / Abbau Gutstunden',
  freetime_616: 'Freistellung bezahlt nach §616 BGB',
  go_back: 'Zurück',
  group: 'Gruppe',
  half_day: 'Halbtags',
  hide: 'Verstecken',
  history_request: 'Bearbeitete Urlaubsanfragen',
  holi_day: 'Feiertag',
  last_updated: 'Zuletzt aktualisiert: {date}',
  like: 'Like',
  liked: 'Like',
  likes: 'Like|Likes',
  loading: 'Lade ...',
  login: 'Login',
  logout: 'Logout',
  menu: 'Menü',
  no_entries: 'Keine Einträge',
  no_more_requests: 'Keine weiteren Anfragen',
  notifications: 'Mitteilungen',
  open_requests: 'Offene Anträge',
  open_vacation_requests: 'Offene Urlaubsanfragen',
  parenting_time: 'Elternzeit',
  parseserver_error_101: 'Falsche Zugangsdaten.',
  parseserver_error_119: 'Die nötigen Berechtigungen fehlen für diese Aktion.',
  parseserver_error_1: 'Ups, etwas ist schief gelaufen.',
  parseserver_error_200: 'Benutzer / Passwort benötigt.',
  parseserver_error_202: 'User mit dieser E-Mail existiert bereits.',
  parseserver_error_141: 'Dieser Benutzer wurde deaktiviert.',
  please_state_mail: 'Bitte Email Adresse angeben',
  please_state_password: 'Bitte Passwort angeben',
  please_state_workspace: 'Bitte Workspace angeben',
  password: 'Passwort',
  posts: 'Beiträge',
  pinned_posts: 'Angeheftete Beiträge',
  post: 'Posten',
  post_placeholder: "Was gibt's Neues ...",
  posting: 'Posting ...',
  pull_to_refresh: 'Ziehen zum Aktualisieren',
  created_by: 'Erstellt von:',
  created_at: 'Erstellt am:',
  reason: 'Grund',
  from_day: 'Von',
  short_vac: 'Urlaub kürzen',
  shorten_vac: 'Gekürzter Urlaub',
  vac_requests: 'Urlaubsanträge',
  reports: 'Berichte',
  import_data: 'Daten importieren',
  manage_groups: 'Gruppen verwalten',
  current_machine: 'Aktuelle Maschine:',
  open_entries: 'Offene Einträge',
  done_entries: 'Abgeschlossene Einträge',
  news: 'Mitteilungen',
  log_out: 'Ausloggen',
  social_business_groups: 'Social Business Gruppen',
  library_tooltip: 'Hier können sie Links zu bestimmten Themen/Seiten anlegen',
  export_tooltip:
    'Hierbei kann die Übersicht bis zu einem bestimmten Monat ausgegeben werden um gegebenenfalls Urlaubstage anzupassen',
  social_tooltip: 'Hier können sie "social-business-Gruppen" anlegen und verwalten',
  wkg_tooltip: 'Hier können sie "working-Gruppen" anlegen und verwalten',
  selected_construction: 'Ausgewählte Baustelle',
  choose_pics: 'Bilder auswählen',
  pics_uploaded: 'Bilder wurden hochgeladen',
  load_more: 'mehr laden',
  you_are_in_no_groups: 'Du bist leider in keinen Gruppen',
  important_links: 'Hier gibt es wichtige Links zu bestimmten Bereichen',
  work_groups: 'Tätigkeitsgruppen',
  employees: 'Angestellte',
  create_report: 'Berichte erstellen',
  data_import_tooltip: 'Hier können sie mithilfe einer csv-Datei Resturlaube importieren',
  deputy_tooltip: 'Diese Funktion ermöglicht es Ihnen Vertreter für bestimmte Gruppen hinzuzufügen bzw. zu löschen',
  daily_report_tooltip: 'Hier kann die Leistung von bestimmten Mitarbeitern aus den Gruppen festgelegt werden',
  dispo_tooltip:
    'Eine speziell angelegte Übersicht für Dispoplaner zur Feststellung der Leistung der Mitarbeiter. Außerdem sind hier Gutstunden und Urlaubstage für jeden Mitarbeiter ersichtlich',
  hr_tooltip:
    'Hier werden die Kommentare der Dispoplanung angelegt. Ersichtlich ist hier: Ersteller, Datum, Kommentar, Erstellungsdatum, Mitarbeiternummer und der Status (erledigt oder nicht)',
  anonym: 'Anonymisiert',
  upload: 'Hochladen',
  employee_year_calendar_tooltip:
    'Eine Funktion um die Urlaubstage eines bestimmten Mitarbeiters auf einen Blick zu haben',
  employee_tooltip: 'Hier können Sie einen neuen Mitarbeiter anlegen und nach einem Mitarbeiter suchen',
  print: 'Drucken',
  export_atm: 'Aktueller Export (Year to Month)',
  download: 'Download',
  no_work_groups: 'Keine Tätigkeitensgruppen vorhanden',
  no_staff: 'Keine Mitarbeiter vorhanden',
  old_vac_requests: 'Vergangene Urlaubsanfragen',
  year_to_month_export: 'Daten exportieren',
  annual_financial_statement: 'Jahresabschluss',
  fix_annual_financial_statement: 'Jahresabschluss fixen',
  successfully_vac_imported: 'Erfolgreich Urlaub übertragen',
  successfully_afs_fixed: 'Erfolgreich Urlaub gefixt',
  make_annual_financial_statement: 'Jahresabschluss durchführen',
  request_new_vac: 'Neuen Urlaub anfragen',
  not_enough_vac: 'Nicht genug Urlaubstage zur Verfügung',
  hint_halfday: 'Hinweis: Um einen einzelnen Tag auszuwählen, klicken Sie zwei Mal dasselbe Datum.',
  active_response_wanted: 'Aktive Rückmeldung ist erwünscht',
  choose_group_if_empty_all: 'Gruppen auwählen',
  no_fitting_workspace: 'Workspace nicht vorhanden. Bitte Eingabe überprüfen!',
  create_vac_request: 'Urlaubsantrag erstellen',
  chosen: 'Ausgewählt:',
  password_doesnt_match: 'Passwörter stimmen nicht überein',
  please_apply_password: 'Bitte Passwort eingeben',
  password_changed: 'Passwort wurde geändert',
  set_deputy: 'Vertreter angelegt!',
  deleted_deputy: 'Vertreter entfernt!',
  chose_pics: 'Bilder auswählen (optional)',
  really: 'wirklich',
  entry_created: 'Eintrag wurde erstellt',
  profile: 'Profil',
  something_went_wrong: 'Etwas ist schiefgelaufen',
  understood: 'Verstanden',
  vac_request_from: 'Urlaubsanfrage von',
  shorten_vac_success: 'Erfolgreich Urlaub gekürzt',
  release_to_load: 'Los lassen zum Laden',
  remember_me: 'Angemeldet bleiben',
  request_created_at: 'Anfrage beantragt am',
  save: 'Speichern',
  school: 'Berufsschule',
  search_employees: 'Mitarbeiter durchsuchen ...',
  select: 'Bitte wählen',
  select_working_group: 'Wähle eine Gruppe',
  from_to: 'Von - Bis',
  without_weekend_days: 'Ohne Wochenendtage',
  new_vacation_for: 'Neuen Urlaub für',
  request: 'anfragen',
  send: 'Senden',
  settings: 'Einstellungen',
  show_all: 'Alle anzeigen',
  show_more: 'Mehr anzeigen',
  sick_with_money: 'Krank mit Lohnfortzahlung',
  status: 'Status',
  finish: 'Abschließen',
  amounts: 'beträgt',
  your_claim_for: 'Dein Anspruch für',
  plus_hours_at_the_end_of_month: 'Gutstunden zum Monatsende:',
  hours: 'Stunden',
  request_reset: 'Anfrage aktualisiert',
  sick_without_money: 'Krank ohne Lohnfortzahlung',
  delete_post: 'Möchten Sie diesen Post wirklich löschen?',
  no: 'Nein',
  post_deleted: 'Beitrag erfolgreich gelöscht!',
  comments: 'Kommentare',
  comment_1: 'Kommentar',
  logs: 'Logs',
  sorted_from_oldest: 'Sortiert vom Ältesten zum Jüngsten',
  deputy_for: 'Vertreter für',
  special_vacation: 'fvacati',
  start: 'Beginn',
  still_open: 'Noch offen',
  submit: 'Bestätigen',
  upload_media: 'Medien hochladen',
  apply: 'Beantragen',
  summary_vac_request: 'Zusammenfassung für neuen Urlaubsantrag',
  used_days: 'Verwendete Tage:',
  rest_vac_import: 'Resturlaub übertragen',
  essential: 'Benötigt',
  has_daily_report: 'Hat Tagesbericht',
  is_active: 'Ist noch angestellt',
  year: 'Jahr',
  working_groups: 'Working Groups',
  working_group_name: 'Working Groups',
  create_new_employee: 'Neuen Mitarbeiter anlegen',
  working_group_color: 'Working Group Farbe',
  create_working_group: 'Working Group anlegen',
  create_social_group: 'Social Business Group anlegen',
  update_social_group: 'Social Business Group bearbeiten',
  social_group_name: 'Name der Social Business Group',
  social_group_color: 'Social Business Group Farbe',
  search_employee: 'Nach Mitarbeiter suchen',
  supervisor: 'Vorgesetzter',
  default_deputy_in_case_of_holiday: 'Standard Stellvertreter (wird automatisch gesetzt, falls Chef im Urlaub)',
  second_supervisor: '2. Vorgesetzter',
  shortcut_working_group: 'Kürzel der Working Group',
  vac_privacy_for_working_group: 'Urlaub für alle Mitarbeiter der Working Group sichtbar',
  second_supervisor_decides_over_vac: 'Anzahl an Tagen, über die der 2. Vorgesetzte entscheiden darf',
  birthdate_equals_pw: 'Geburtsdatum (Passwort wird daraus generiert: YYYY-MM-DD)',
  rest_vac_success: 'Resturlaub erfolgreich hinzugefügt',
  month: 'Monat',
  update: 'Update',
  update_1: 'Aktualisieren',
  optional: 'Optional',
  claim: 'Anspruch:',
  taken: 'Genommen:',
  rest_vac_1: 'Resturlaub:',
  staff_details: 'Mitarbeiter Details',
  staff_1: 'Mitarbeiter:',
  no_uploaded_data: 'Keine Datei hochgeladen',
  deputy_for_area: 'Vertreter für Abteilungen',
  lastname_1: 'Name',
  chef: 'Chef',
  short_chef: 'Short Chef',
  deputy: 'Vertreter',
  no_comments: 'Keine Kommentare',
  hide_empty_cells: 'Leere Zeilen ausblenden',
  vac: 'Urlaub',
  color_desc: 'Grün = Akzeptiert, Rot = Abgelehnt, Grau = Storno, Blau = Offen, Orange = Resturlaub',
  daily_report: 'Tagesbericht',
  purple_desc: 'Lila = Tagesbericht vorhanden',
  set_as_default: 'Auswahl als Standard festlegen',
  set_new_default: 'Neuen Standard festgelegt!',
  no_vac_type: 'Keinem Urlaubstyp zugewiesen',
  no_open_requests: 'Keine offenen Anträge',
  no_old_requests: 'Keine vergangenen Anträge',
  increase_time: 'Zeitraum erhöhen',
  weekends_holiday: 'WE/FT',
  watch: 'Anschauen',
  request_sent: 'Anfrage gesendet',
  check: 'check',
  show_legend: 'Legende anzeigen',
  needed_file_for_import:
    'Es wird eine .csv Datei benötigt, in der jeder "Urlaub" und "von_datum"(Format:\n' +
    '            DD/MM/YYYY) einer "mitPersNr" zugeordnet ist.',
  back_to_overview: 'Zurück zur Übersicht',
  username: 'Benutzername',
  vacation: 'Urlaub',
  vacation_request: 'Urlaubsanfrage',
  vacation_request_1: 'Urlaubsanfrage:',
  vacation_requests_starting_soon: 'Bald beginnende Urlaubsanfragen',
  vacation_without_money: 'Urlaub unbezahlt',
  viewed_by: 'Gesehen von',
  visible_for: 'Sichtbar für:',
  welcome: 'Willkommen',
  working_group_calendar: 'Teamkalender',
  year_calendar: 'Jahreskalender',
  workinggroup_calendar: 'Teamkalender',
  performance_recorder: 'Leistungserfasser',
  apply_working_time: 'Arbeitszeiten anfordern',
  dispo_planner: 'Dispo - Planer',
  todos: "HR ToDo's",
  vacation_without_fr_workflow: 'Urlaub ohne Freigeber Workflow',
  one_day_hint: 'Hinweis: Um einen einzelnen Tag auszuwählen, klicken Sie zwei Mal dasselbe Datum.',
  confirm: 'Bestätigen',
  declined: 'Abgelehnt',
  cancel: 'Stornieren',
  january: 'Januar',
  february: ' Februar',
  march: 'März',
  april: 'April',
  may: 'Mai',
  june: 'Juni',
  july: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',
  cancel_1: 'Abbrechen',
  cancel_2: 'stornieren',
  groups: 'Gruppen',
  yes_1: 'Ja,',
  no_dont: 'Nein, nicht',
  deleted_successfully: 'Erfolgreich gelöscht!',
  canceled_successfully: 'Erfolgreich storniert!',
  excel_export: 'Excel export',
  staff: 'Mitarbeiter',
  admin_area: 'Adminbereich',
  my_groups: 'Meine Gruppen',
  my_vacation_overview: 'Meine Urlaubsübersicht',
  open: 'Offen',
  accepted: 'Akzeptiert',
  canceled: 'Storniert',
  construction_images: 'Baustellenbilder',
  select_construction: 'Baustelle wählen',
  event_was_added: 'Event wurde hinzugefügt',
  U_accepted: 'Urlaub angenommen',
  OU_open: 'Urlaub offen',
  U_declined: 'Urlaub abgelehnt',
  U_canceled: 'Urlaub storniert',
  admin: 'Admin',
  working_hours: 'Arbeitszeit',
  machine_diary: 'Gerätetagebuch',
  select_machine: 'Gerät auswählen',
  open_vacation: 'Offener Urlaub',
  workspace: 'Workspace',
  no_notifications_yet: 'Noch keine Mitteilungen ...',
  no_posts_yet: 'Noch keine Posts...',
  no_links_yet: 'Noch keine Links...',
  add_shorten_vac: 'Urlaub kürzen',
  add_extra_days: 'Extraurlaub hinzufügen',
  no_extra_days: 'Kein Extraurlaub',
  no_shorten_vacs: 'Kein gekürzter Urlaub',
  no_requests_yet: 'Keine Urlaubsanfragen',
  startDate: 'Startdatum',
  canceled_2: 'Storno',
  extra_days: 'Extraurlaub',
  document_pool: 'Dokumenten Pool',
  upload_document: 'Dokument hochladen',
  'Error: ParseError: 141 user_was_deactivated': 'Dieser Benutzer wurde deaktiviert',
  'Error: tenant_not_found': 'Dieser Workspace wurde nicht gefunden.',
  reset: 'Zurücksetzen',
  sick_report_exist: 'Krankmeldung vorliegen',
  update_successful: 'Aktualisierung erfolgreich',
  maternity_protection: 'Mutterschutz',
  quarantine_without_money: 'Quarantäne ohne Lohnfortzahlung',
  quarantine_with_money: 'Quarantäne mit Lohnfortzahlung',
  new_vacation_request: 'Neuer Antrag',
  create_new_post: 'Neuen Post erstellen',
  create_post: 'Post erstellen',
  post_created: 'Post erstellt',
  back: 'Zurück',
  pin_this_post: 'Diesen Beitrag anpinnen',
  delete_this_post: 'Diesen Beitrag löschen',
  next_step: 'Nächster Schritt',
  summary: 'Zusammenfassung',
  favourites: 'Favoriten',
  added_favourite: 'Favorit hinzugefügt!',
  removed_favourite: 'Favorit entfernt!',
  admin_section: 'Adminbereich',
  contact_information: 'Kontaktinformationen',
  legend: 'Legende',
  enter_note_here: 'Hier Notiz eintragen',
  amount_of_days: 'Anzahl Tage',
  days_must_be_above_0: 'Anzahl der Tage muss über 1 sein',
  extra_days_added: 'Extra Tage wurden hinzugefügt',
  vacation_request_created: 'Urlaubsanfrage wurde erstellt!',
  edit_deputy: 'Vertreter bearbeiten',
  create_calendar_event: 'Kalenderereignis anlegen',
  update_calendar_event: 'Kalenderereignis aktualisieren',
  translation: 'Übersetzung',
  calendar_event_background: 'Hintergrundfarbe',
  calendar_event_color: 'Textfarbe',
  extra: 'Extra',
  key: 'Schlüssel',
  preview: 'Vorschau',
  search_calendar_event: 'Nach Kalenderereignis suchen',
  german: 'Deutsch',
  english: 'Englisch',
  example: 'Beispiel',
  pin: 'Anpinnen',
  edit_post: 'Post bearbeiten',
  edit_post_success: 'Post bearbeiten erfolgreich',
  unpin: 'Entpinnen',
  pin_successful: 'Anpinnen erfolgreich',
  unpin_successful: 'Entpinnen erfolgreich',
  planner: 'Planer',
  date: 'Datum',
  employee_on_vacation: 'Arbeitnehmer im Urlaub',
  search_result: 'Suchergebnisse',
  added_folder_success: 'Ordner erfolgreich hinzugefügt!',
  added_file_success: 'Datei erfolgreich hinzugefügt!',
  added_tag_success: 'Tag erfolgreich hinzugefügt!',
  no_results: 'Keine Treffer',
  select_tags: 'Tags auswählen',
  enter_name: 'Bitte mindestens einen Namen eingeben!',
  choose_documents: 'Dateien auswählen',
  add_tag: 'Tag hinzufügen',
  new_tag_name: 'Name',
  make_photo: 'Foto machen',
  choose_from_gallery: 'Von der Galerie auswählen',
  select_action: 'Aktion auswählen',
  add_date_time: 'Datum auf das Bild schreiben',
  selected_contruction: 'Ausgewählte Baustelle',
  next: 'Weiter',
  not_translated: 'Nicht übersetzt',
  pls_enter_reason: 'Grund eingeben',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  vacationdays: 'Urlaubstage',
  sokabau: 'SoKa-Bau',
  vacation_type: 'Urlaubstyp',
  general: 'Allgemein',
  misc: 'Mehr Informationen',
  download_success: 'Download erfolgreich',
  click_to_open: 'Klicken zum Öffnen',
  updated_request: 'Anfrage aktualisiert',
  enter_reason: 'Grund eingeben',
  select_two_days: 'Bitte 2 Tage auswählen',
  to_do: 'To-do-Listen',
  add_note: 'Notiz hinzufügen',
  leaving_date: 'Arbeitsverhältnis beendet am:',
  workinggroup: 'Abteilung|Abteilungen',
  press_enter_to_search: 'Enter drücken, um zu suchen',
  i_like: 'Gefällt mir',
  dont_like_it: 'Gefällt mir nicht mehr',
  server_connection_lost: 'Verbindung zum Internet fehlgeschlagen!',
  retry: 'Nochmal versuchen',
  no_rights_pls_inform_your_admin: 'Keine Berechtigung. Bitte informiere deinen Admin.',
  error: 'Error',
  upload_profileimage: 'Profilbild hochladen',
  camera: 'Kamera ...',
  photo_library: 'Aus Medien wählen ...',
  delete_profile_image: 'Profilbild löschen',
  reset_password_admin_hint: 'Das Passwort wird für diesen User auf das Geburtsdatum zurückgesetzt. (YYYYMMDD)',
  reset_password: 'Password zurücksetzen',
  passwort_reset_successful: 'Passwort wurde erfolgreich zurückgesetzt',
  invalid_birthdate: 'Ungültiges oder nicht vorhandenes Geburtsdatum',
  yes: 'Ja',
  password_reset_confirm: 'Passwort wirklich zurücksetzen?',
};
