import { modalController } from '@ionic/vue';

/**
 *
 * @param component
 * @param {Object} componentProps
 * @param {Object} modalProps
 * @param {String[]|String} modalProps.cssClass
 * @param {Boolean} modalProps.swipeToClose
 * @param {Boolean} modalProps.initialBreakpoint
 * @param {Boolean} modalProps.breakpoints
 * @returns {Promise<void>}
 */
const show = async (component, componentProps, modalProps) => {
  const modal = await modalController.create({
    component,
    componentProps,
    cssClass: modalProps.cssClass,
    swipeToClose: modalProps.swipeToClose,
    initialBreakpoint: modalProps.initialBreakpoint ? modalProps.initialBreakpoint : undefined,
    breakpoints: modalProps.breakpoints ? modalProps.breakpoints : undefined,
  });
  await modal.present();
};

const close = async () => {
  await modalController.dismiss();
};

const ionicModalController = {
  show,
  close,
};

export default ionicModalController;
