import { createRouter, createWebHistory } from '@ionic/vue-router';
import Parse from 'parse';
import store from '../store';
import Default from '../layouts/Default.vue';
import Login from '../pages/login/index.vue';
import sideMenuItems from '../config/sidemenu-items.js';
import adminSideMenuItems from '../config/admin-sidemenu-items.js';
const routes = [
  {
    path: '/',
    name: 'root',
    component: Default,
    redirect: '/social-business',
    children: [
      {
        path: '/social-business',
        component: () => import('../pages/social-business/index.vue'),
        name: 'social-business',
      },
      {
        path: '/calendar',
        component: () => import('../pages/calendar/index.vue'),
        name: 'calendar',
      },
      {
        path: '/calendar/employees',
        component: () => import('../pages/calendar/employees.vue'),
        name: 'calendar-employees',
      },
      {
        path: '/construction-images',
        component: () => import('../pages/construction-images/index.vue'),
        name: 'construction-images',
      },
      {
        path: '/groups',
        component: () => import('../pages/groups/index.vue'),
        name: 'groups',
      },
      {
        path: '/groups/:group',
        component: () => import('../pages/groups/_group/index.vue'),
        name: 'groups-group',
      },
      {
        path: '/library',
        component: () => import('../pages/library/index.vue'),
        name: 'library',
      },
      {
        path: '/machine-diary',
        component: () => import('../pages/machine-diary/index.vue'),
        name: 'machine-diary',
      },
      {
        path: '/notifications',
        component: () => import('../pages/notifications/index.vue'),
        name: 'notifications',
      },
      {
        path: '/posts/:id',
        component: () => import('../pages/posts/_id/index.vue'),
        name: 'posts-id',
      },
      {
        path: '/posts/:id/analytics',
        component: () => import('../pages/posts/_id/analytics/index.vue'),
        name: 'posts-id-analytics',
      },
      {
        path: '/profile',
        component: () => import('../pages/profile/index.vue'),
        name: 'profile',
      },
      {
        path: '/settings',
        component: () => import('../pages/settings/index.vue'),
        name: 'settings',
      },
      {
        path: '/to-do',
        component: () => import('../pages/to-do/index.vue'),
        name: 'to-do',
      },
      {
        path: '/vacation',
        component: () => import('../pages/vacation/index.vue'),
        name: 'vacation',
      },
      {
        path: '/vacation/calendar',
        component: () => import('../pages/vacation/calendar.vue'),
        name: 'vacation-calendar',
      },
      {
        path: '/vacation/events',
        component: () => import('../pages/vacation/events.vue'),
        name: 'vacation-events',
      },
      {
        path: '/vacation/me',
        component: () => import('../pages/vacation/me.vue'),
        name: 'vacation-me',
      },
      {
        path: '/vacation/create',
        component: () => import('../pages/vacation/create.vue'),
        name: 'vacation-create',
      },
      {
        path: '/vacation/created',
        component: () => import('../pages/vacation/created.vue'),
        name: 'vacation-created',
      },
      {
        path: '/vacation/requests',
        component: () => import('../pages/vacation/requests.vue'),
        name: 'vacation-requests',
      },
      {
        path: '/vacation/summary',
        component: () => import('../pages/vacation/summary.vue'),
        name: 'vacation-summary',
      },
      {
        path: '/working-hours',
        component: () => import('../pages/working-hours/index.vue'),
        name: 'working-hours',
      },
      {
        path: '/document-pool/:catchAll(.*)*',
        component: () => import('../pages/document-pool/index.vue'),
        name: 'document-pool',
      },
      {
        path: '/loading',
        component: () => import('../pages/loading/index.vue'),
        name: 'loading',
      },
      {
        path: '/menu',
        component: () => import('../pages/menu/index.vue'),
        name: 'menu',
      },
      {
        path: '/admin',
        component: () => import('../pages/admin/index.vue'),
        name: 'admin',
      },
      {
        path: '/admin/calendar',
        component: () => import('../pages/admin/calendar/index.vue'),
        name: 'admin-calendar',
      },
      {
        path: '/admin/cal-events',
        component: () => import('../pages/admin/cal-events/index.vue'),
        name: 'admin-cal-events',
      },
      {
        path: '/admin/daily-report',
        component: () => import('../pages/admin/daily-report/index.vue'),
        name: 'admin-daily-report',
      },
      {
        path: '/admin/data-import',
        component: () => import('../pages/admin/data-import/index.vue'),
        name: 'admin-data-import',
      },
      {
        path: '/admin/deputy',
        component: () => import('../pages/admin/deputy/index.vue'),
        name: 'admin-deputy',
      },
      {
        path: '/admin/dispoplaner',
        component: () => import('../pages/admin/dispoplaner/index.vue'),
        name: 'admin-dispoplaner',
      },
      {
        path: '/admin/employee-year-calendar',
        component: () => import('../pages/admin/employee-year-calendar/index.vue'),
        name: 'admin-employee-year-calendar',
      },
      {
        path: '/admin/end-year',
        component: () => import('../pages/admin/end-year/index.vue'),
        name: 'admin-end-year',
      },
      {
        path: '/admin/hrtodos',
        component: () => import('../pages/admin/hrtodos/index.vue'),
        name: 'admin-hrtodos',
      },
      {
        path: '/admin/library',
        component: () => import('../pages/admin/library/index.vue'),
        name: 'admin-library',
      },
      {
        path: '/admin/manage-groups',
        component: () => import('../pages/admin/manage-groups/index.vue'),
        name: 'admin-manage-groups',
      },
      {
        path: '/admin/past-vacation',
        component: () => import('../pages/admin/past-vacation/index.vue'),
        name: 'admin-past-vacation',
      },
      {
        path: '/admin/reports',
        component: () => import('../pages/admin/reports/index.vue'),
        name: 'admin-reports',
      },
      {
        path: '/admin/user',
        component: () => import('../pages/admin/user/index.vue'),
        name: 'admin-user',
      },
    ],
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  /*
  const status = await Network.getStatus();
  console.log(status);
  if (to.name === 'loading') {
    next();
    return;
  }
  if (!status.connected) {
    next({ name: 'loading' });
    return;
  }
  */
  let hasUser = false;
  Object.keys(localStorage).forEach((el) => {
    if (el.includes('currentUser')) {
      hasUser = true;
    }
  });
  const IS_FEESS = Parse.serverURL.includes('feess');
  if ((!Parse.applicationId || IS_FEESS) && !to.path.includes('login') && !hasUser) {
    next({ name: 'login' });
    return;
  }
  if (!hasUser && to.path.includes('login')) {
    next();
    return;
  }

  if (hasUser && to.path.includes('login')) {
    next({ name: 'social-business' });
    return;
  }
  try {
    if (hasUser && !Parse.User.current().get('isActive')) {
      await store.dispatch('auth/logout');
      next({ name: 'login' });
      return;
    }
  } catch (e) {
    console.log(e);
    removeAllLocalStorage();
    next({ name: 'login' });
    return;
  }

  // checking if user is allowed to navigate to to.name
  // if not, navigating to a page he is allowed to
  let userRoles = store.getters['auth/roles'];
  if (userRoles.length === 0) {
    userRoles = await store.dispatch('auth/loadRoles');
  }
  const allMenuItems = sideMenuItems.concat(adminSideMenuItems);
  const sitesUserCanVisit = allMenuItems.filter((el) => {
    const roles = el.roles;
    return userRoles.some((el2) => roles.includes(el2));
  });
  if (sitesUserCanVisit.length === 0 && to.name !== 'loading') {
    next({ name: 'loading' });
    return;
  }

  if (to.name === 'loading') {
    next();
    return;
  }
  const sideMenuItemsRouteNames = allMenuItems.map((el) => el.routeName);
  const sitesUserCanVisitRouteNames = sitesUserCanVisit.map((el) => el.routeName);

  if (sideMenuItemsRouteNames.includes(to.name) && !sitesUserCanVisitRouteNames.includes(to.name)) {
    next({ name: sitesUserCanVisit.filter((el) => el.class !== 'hide-on-desktop')[0].routeName });
    return;
  }

  if (!store.getters['auth/isEditor'] && to.path.includes('analytics')) {
    next({ name: 'index' });
    return;
  }
  next();
});

function removeAllLocalStorage() {
  localStorage.removeItem('tenant-data-app-id');
  localStorage.removeItem('tenant-data-js-key');
  localStorage.removeItem('tenant-data-server-url');
  localStorage.removeItem('tenant-data-features');
  localStorage.removeItem('companyColor');
  localStorage.removeItem('Parse/feessApp/installationId');
  localStorage.removeItem('Parse/feessApp/currentUser');
}

export default router;
