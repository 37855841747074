import Parse from 'parse';

const CalendarEventEntriesModule = {
  namespaced: true,
  state: {
    calendarEvents: {},
  },
  mutations: {
    addOrEditCalendarEvent(state, newEvent) {
      const event = newEvent.get('customData');
      event.translation = newEvent.get('translation');
      event.key = newEvent.get('key');
      event.id = newEvent.id;
      state.calendarEvents[event.key] = event;
    },
    removeCalendarEvent(state, deletedKey) {
      delete state.calendarEvents[deletedKey];
    },
    resetCalendarEvent(state, oldEvent) {
      state.calendarEvents[oldEvent.key] = oldEvent;
    },
    setCalendarEvents(state, calendarEvents) {
      state.calendarEvents = calendarEvents;
    },
  },
  actions: {
    async addOrEditCalendarEvent({ commit }, payload) {
      const tuple = new Parse.Object('CalendarEventEntry');
      if (payload.id) tuple.id = payload.id;
      tuple.set('key', payload.key);

      tuple.set('customData', {
        name: payload.name,
        color: payload.color,
        background: payload.background,
      });

      tuple.set('translation', payload.translation);

      try {
        const res = await tuple.save();
        commit('addOrEditCalendarEvent', res);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteCalendarEvent({ commit }, payload) {
      const tuple = new Parse.Object('CalendarEventEntry');
      tuple.id = payload.id;
      try {
        await tuple.destroy();
        commit('removeCalendarEvent', payload.key);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteCalendarEvents({}, payload) {
      const query = new Parse.Query('CalendarEvent');
      query.equalTo('type', payload.key);
      query.select();
      try {
        const res = await query.find();
        for (const tuple of res)
          try {
            await tuple.destroy();
          } catch (err) {
            console.log(err);
          }
      } catch (err) {
        console.log(err);
      }
    },
    async loadCalendarEvents({ commit }) {
      try {
        const calendarEvents = await new Parse.Query('CalendarEventEntry').limit(100).find();
        const result = {};
        for (let i = 0; i < calendarEvents.length; i++) {
          result[calendarEvents[i].get('key')] = calendarEvents[i].get('customData');
          result[calendarEvents[i].get('key')].translation = calendarEvents[i].get('translation');
          result[calendarEvents[i].get('key')].key = calendarEvents[i].get('key');
          result[calendarEvents[i].get('key')].id = calendarEvents[i].id;
        }
        commit('setCalendarEvents', result);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    calendarEvents: (state) => state.calendarEvents,
  },
};

export default CalendarEventEntriesModule;
