import Parse from 'parse';
import { multiEntityLoading } from '@/store/mixins/loading-states';

const HrTodosModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    entries: [],
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setEntries(state, ee) {
      state.entries = ee;
    },
    updateEntry(state, id) {
      state.entries.find((el) => el.data.id === id).data.isDone = true;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadHrTodos({ commit, dispatch }) {
      try {
        const query = new Parse.Query('DispoMessage');
        query.include('employee');
        query.include('user');
        query.limit(3000);
        query.notEqualTo('isDone', true);
        query.addDescending(['createdAt']);

        let results = await dispatch('handleLoadingState', {
          entity: 'hr-todos',
          promise: query.find(),
        });
        results = results.map((el) => {
          return {
            data: {
              employeeName: `${el.get('employee').get('firstname')} ${el.get('employee').get('lastname')}`,
              employeeId: el.get('employee').get('employeeId'),
              createdAt: el.createdAt,
              isDone: el.get('isDone'),
              dayString: el.get('dayString'),
              day: el.get('day'),
              id: el.id,
              message: el.get('message'),
              creator: `${el.get('user').get('firstname')} ${el.get('user').get('lastname')}`,
            },
            object: el,
          };
        });
        console.log(results);
        commit('setEntries', results);
      } catch (e) {
        commit('setEntries', []);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    entries: (state) => state.entries,
  },
};
export default HrTodosModule;
