import Parse from 'parse';

const EmployeesOnVacationModule = {
  namespaced: true,
  state: {
    employees: [],
    fetching: false,
    lastFetched: null,
  },
  mutations: {
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    SET_FETCHING(state, fetching) {
      state.fetching = fetching;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
  },
  actions: {
    async loadEmployeesOnVacation({ commit }, payload) {
      commit('SET_FETCHING', true);
      const { date, group } = payload;
      const parsedDate = new Date(date);
      const includeAllStatus = !!payload.showAll;

      parsedDate.setHours(0);

      const vQuery = new Parse.Query('VacationRequest');
      if (!includeAllStatus) vQuery.equalTo('status', 'accepted');
      vQuery.notEqualTo('status', 'declined');
      vQuery.include('user');
      vQuery.greaterThanOrEqualTo('requestTo', parsedDate);
      vQuery.lessThanOrEqualTo('requestFrom', parsedDate);

      try {
        const requests = await vQuery.find();
        const users = [];

        for (const request of requests) {
          const user = request.get('user');

          const WorkingGroups = Parse.Object.extend('WorkingGroup');
          const query = new Parse.Query(WorkingGroups);
          query.equalTo('users', user);
          query.equalTo('name', group);
          const workingGroups = await query.find();

          const userInWorkingGroup = workingGroups.map((workingGroup) => {
            const _users = workingGroup.get('users');
            return _users.find((u) => u.id === user.id);
          });

          if (users.findIndex((u) => u.user.id === user.id) === -1 && userInWorkingGroup.length) {
            users.push({
              user,
              request,
            });
          }
        }
        commit('SET_EMPLOYEES', users);
        commit('setLastFetch', new Date());
        return users;
      } catch (e) {
        commit('SET_EMPLOYEES', null);
      } finally {
        commit('SET_FETCHING', false);
      }
    },
  },
  getters: {
    getEmployees: (state) => state.employees,
    getFetching: (state) => state.fetching,
    lastFetched: (state) => state.lastFetched,
  },
};
export default EmployeesOnVacationModule;
