const ResizeModule = {
  namespaced: true,
  state: {
    isMobile: false,
  },
  mutations: {
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile;
    },
  },
  actions: {
    updateIsMobile({ commit }, payload) {
      commit('SET_IS_MOBILE', payload);
    },
  },
  getters: {
    getIsMobile: (state) => state.isMobile,
  },
};
export default ResizeModule;
