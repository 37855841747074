import Parse from 'parse';
import moment from 'moment';

const YearCalendarModule = {
  namespaced: true,
  state: {
    dates: null,
    month: null,
    user: null,
    type: null,
    year: null,
    notes: null,
  },
  mutations: {
    setDates(state, dates) {
      state.dates = dates;
    },
    setMonth(state, month) {
      state.month = month;
    },
    setUser(state, user) {
      state.user = user;
    },
    setType(state, type) {
      state.type = type;
    },
    setYear(state, year) {
      state.year = year;
    },
    setNotes(state, notes) {
      state.notes = notes;
    },
  },
  actions: {
    async saveDates({ commit, state }, notes) {
      for (let i = 1; i < state.dates.length; i++) {
        const date = new Date(state.year, state.month, state.dates[i], 8);
        const o = new Parse.Object('CalendarEvent');
        o.set('user', state.user, null);
        o.set('day', moment(date).add('5', 'h').toDate(), null);
        o.set('type', state.type, null);
        o.set('notes', notes, null);
        await o.save(null);
      }
      commit('setDates', null);
      commit('setMonth', null);
      commit('setUser', null);
      commit('setType', null);
      commit('setYear', null);
      commit('setNotes', null);
    },
  },
  getters: {
    dates: (state) => state.dates,
    month: (state) => state.month,
    user: (state) => state.user,
    type: (state) => state.type,
    year: (state) => state.year,
    notes: (state) => state.notes,
  },
};
export default YearCalendarModule;
