<template>
  <div class="relative">
    <div
      v-if="!user.get('profileImage')"
      :class="`rounded-full element-shadow flex justify-center items-center font-black size-${size}`"
      :style="{
        background: circleBackground,
        color: circleForeground,
      }"
    >
      <div>
        {{ `${user.get('firstname').substring(0, 1)}${user.get('lastname').substring(0, 1)}` }}
      </div>
    </div>
    <div v-else :class="`rounded-full size-${size}`">
      <img
        v-if="user.get('profileImage').url()"
        :key="uniqueKey"
        ref="imageRef"
        alt="profileImage"
        :class="`rounded-full aspect-square object-cover size-${size}`"
        :src="user.get('profileImage').url()"
      />
    </div>
    <div
      v-if="canEdit && !isUploading"
      class="absolute right-0 bottom-0 bg-white shadow flex items-center text-primary gap-2 border cursor-pointer hover:bg-gray-100 transition-all duration-300 justify-center rounded-full w-8 h-8 p-1"
      @click="onPhoto"
    >
      <i class="material-icons text-base">photo</i>
    </div>
    <div
      v-if="isUploading"
      class="absolute top-0 right-0 w-full h-full bg-white rounded-full border border-primary z-10 flex justify-center items-center"
    >
      <i class="material-icons text-3xl text-primary animate-bounce">hourglass_empty</i>
    </div>
  </div>
</template>

<script setup>
import Parse from 'parse';
import { hexToRgb } from '@/js/color-helper.js';
import { computed, toRefs, ref } from 'vue';
import { actionSheetController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { getCurrentInstance } from 'vue';

const instance = getCurrentInstance();

const emitter = instance.appContext.config.globalProperties.emitter;

const { t } = useI18n();

const uniqueKey = ref(0);
const imageRef = ref(null);
const isUploading = ref(false);

const props = defineProps({
  user: { type: Parse.User, required: true },
  canEdit: { type: Boolean, default: false },
  size: {
    type: String,
    default: 'xl',
    validator(value) {
      return ['sm', 'lg', 'xl'].includes(value);
    },
  },
});

const { user } = toRefs(props);

const circleBackground = computed(() => {
  return user.value.get('color') || this.$store.getters['company-style/companyStyle'].primary;
});

const circleForeground = computed(() => {
  return hexToRgb(circleBackground.value);
});

const onPhoto = async () => {
  const actionSheet = await actionSheetController.create({
    header: t('upload_profileimage'),
    buttons: [
      {
        text: t('camera'),
        data: {
          action: 'camera',
        },
      },
      {
        text: t('photo_library'),
        data: {
          action: 'library',
        },
      },
      {
        text: t('delete_profile_image'),
        role: 'destructive',
        data: {
          action: 'delete',
        },
      },
      {
        text: t('cancel_1'),
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      },
    ],
  });

  await actionSheet.present();

  const {
    data: { action },
  } = await actionSheet.onDidDismiss();

  if (action === 'camera') {
    await onCamera();
  }
  if (action === 'library') {
    await onPhotoLibrary();
  }
  if (action === 'delete') {
    isUploading.value = true;
    user.value.set('profileImage', null);
    await user.value.save();
    isUploading.value = false;
  }

  emitter.emit('profileImageChanged', true);
};

const onCamera = async () => {
  const image = await Camera.getPhoto({
    quality: 100,
    allowEditing: true,
    width: 1000,
    height: 1000,
    resultType: CameraResultType.Base64,
    source: CameraSource.Camera,
  });
  await saveImage(image);
};

const onPhotoLibrary = async () => {
  const image = await Camera.getPhoto({
    quality: 100,
    allowEditing: true,
    width: 1000,
    height: 1000,
    resultType: CameraResultType.Base64,
    source: CameraSource.Photos,
  });
  await saveImage(image);
};

const saveImage = async ({ base64String, format }) => {
  let file = new Parse.File(
    `${user.value.get('firstname')}-${user.value.get('lastname')}-profilepic.${format}`,
    {
      base64: base64String,
    },
    format
  );
  isUploading.value = true;
  user.value.set('profileImage', file);
  await user.value.save();
  isUploading.value = false;
};

const onPhotoChanged = () => {
  uniqueKey.value += 1;
  instance?.proxy?.$forceUpdate();
};

emitter.on('profileImageChanged', onPhotoChanged);
</script>

<style scoped>
.size-sm {
  @apply w-14 h-14 text-lg;
}
.size-lg {
  @apply w-20 h-20 text-2xl;
}

.size-xl {
  @apply w-40 h-40 text-5xl;
}
</style>
