import Parse from 'parse';
import { Device } from '@capacitor/device';

const InstallationModule = {
  namespaced: true,
  state: {
    installationId: null,
    installation: null,
  },
  mutations: {
    setInstallationId(state, id) {
      state.installationId = id;
    },
    setInstallation(state, installation) {
      state.installation = installation;
    },
  },
  actions: {
    async loadInstallation({ commit }) {
      try {
        const info = await Device.getInfo();

        const installation = await Parse.Cloud.run('getInstallation', { os: info.operatingSystem }, {});
        commit('setInstallation', installation);
        commit('setInstallationId', installation.get('installationId'));
        return installation;
      } catch (error) {
        commit('setInstallation', null);
        return null;
      }
    },
    async setDeviceToken({ commit }, { token, os }) {
      try {
        const installation = await Parse.Cloud.run('setDeviceToken', { deviceToken: token, os }, null);
        commit('setInstallation', installation);
        commit('setInstallationId', installation.get('installationId'));
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
  },
  getters: {
    installation: (state) => state.installation,
    installationId: (state) => state.installationId,
  },
};
export default InstallationModule;
