import Parse from 'parse';

const ConfirmerCalendarModule = {
  namespaced: true,
  state: {
    vacationRequest: null,
    items: {},
  },
  mutations: {
    setVacationRequest(state, request) {
      state.vacationRequest = request;
    },
    setItems(state, items) {
      state.items = items;
    },
    resetItems(state) {
      state.items = [];
      state.vacationRequest = null;
    },
  },
  actions: {
    async loadSelectedVacationRequest({ commit, state, dispatch }, { objectId, x }) {
      try {
        const query = new Parse.Query('VacationRequest');
        query.include('user');
        const response = await query.get(objectId, null);
        commit('setVacationRequest', response);
        await dispatch('loadEmployeesWithVacationAtThisTimeFromWorkingGroup', x);
      } catch (e) {
        commit('setVacationRequest', null);
      }
    },
    async loadEmployeesWithVacationAtThisTimeFromWorkingGroup({ commit, state }, x = 0) {
      try {
        const user = state.vacationRequest.get('user');

        const groupQuery = new Parse.Query('WorkingGroup');
        groupQuery.equalTo('users', user);
        groupQuery.include('users');
        groupQuery.include('chef');
        groupQuery.include('shortChef');
        let groups = await groupQuery.find();

        groups = groups.filter((el) => {
          const chef = el.get('chef');
          const shortChef = el.get('shortChef');
          const ids = [];
          if (chef) ids.push(chef.id);
          if (shortChef) ids.push(shortChef.id);

          return ids.includes(Parse.User.current().id);
        });
        const result = {};
        for (let i = 0; i < groups.length; i++) {
          const requestTo = new Date(state.vacationRequest.get('requestTo'));
          const requestFrom = new Date(state.vacationRequest.get('requestFrom'));
          const requestToMonth = requestTo.getMonth();
          const requestToYear = requestTo.getFullYear();

          const requestFromMonth = requestFrom.getMonth();
          const requestFromYear = requestFrom.getFullYear();

          const query = new Parse.Query('VacationRequest');
          query.containedIn('user', groups[i].get('users'));
          query.include('user');
          query.notEqualTo('status', 'declined');
          query.containedIn('years', [requestToYear, requestFromYear]);
          query.containedIn('months', [requestToMonth, requestFromMonth]);
          query.limit(3000);

          const response = await query.find();

          /*
          const requestTo1 = new Date(state.vacationRequest.get('requestTo'));
          requestTo1.setDate(requestTo1.getDate() + x);
          const requestFrom1 = new Date(state.vacationRequest.get('requestFrom'));
          requestFrom1.setDate(requestFrom1.getDate() - x);
          query.lessThanOrEqualTo('requestTo', requestTo1);
          query.greaterThanOrEqualTo('requestTo', requestFrom1);
          const query1 = new Parse.Query('VacationRequest');
          query1.containedIn('user', groups[i].get('users'));
          query1.include('user');
          query1.notEqualTo('status', 'declined');

          const requestFrom2 = new Date(state.vacationRequest.get('requestFrom'));
          requestFrom2.setDate(requestFrom2.getDate() - x);
          const requestTo2 = new Date(state.vacationRequest.get('requestTo'));
          requestTo2.setDate(requestTo2.getDate() + x);

          query1.greaterThanOrEqualTo('requestFrom', requestFrom2);
          query1.lessThanOrEqualTo('requestFrom', requestTo2);

          const orQuery = Parse.Query.or(query, query1);
          query.limit(3000);
          const response = await orQuery.find();
           */

          const items = response.map((el) => {
            return {
              name: `${el.get('user').get('firstname')} ${el.get('user').get('lastname')}`,
              days: el
                .get('days')
                .filter((el) => el.value > 0)
                .map((el) => el.date),
              color: el.get('user').get('color'),
              el,
              status: el.get('status'),
            };
          });

          result[groups[i].id] = { items, name: groups[i].get('name') };
        }
        commit('setItems', result);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    vacationRequest: (state) => state.vacationRequest,
    items: (state) => state.items,
  },
};
export default ConfirmerCalendarModule;
