import Parse from 'parse';

const MachineDiaryModule = {
  namespaced: true,
  state: {
    machines: null,
    entries: null,
    currentMachine: null,
  },
  mutations: {
    setMachines(state, machines) {
      state.machines = machines;
    },
    setEntries(state, entries) {
      state.entries = entries;
    },
    setCurrentMachine(state, machine) {
      state.currentMachine = machine;
    },
  },
  actions: {
    async loadMachines({ commit }) {
      try {
        const response = await Parse.Cloud.run('loadMachines', {}, null);
        commit('setMachines', response);
      } catch (e) {
        commit('setMachines', null);
      }
    },
    async loadEntries({ commit }, machine) {
      try {
        const response = await Parse.Cloud.run('loadEntries', { machineId: machine.id }, {});
        commit('setEntries', response);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    machines: (state) => state.machines,
    entries: (state) => state.entries,
    currentMachine: (state) => state.currentMachine,
  },
};
export default MachineDiaryModule;
