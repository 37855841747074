import Parse from 'parse';

const AdminModule = {
  namespaced: true,
  state: {
    users: [],
    userOptions: [],
    userVacation: [],
    userExtraVacations: [],
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setUserVacation(state, userVacation) {
      state.userVacation = userVacation;
    },
    updateUser(state, updatedUser) {
      const ind = state.users.findIndex((el) => el.id === updatedUser.id);
      state.users.splice(ind, 1, updatedUser);
    },
    deleteUserVacation(state, id) {
      const i = state.userVacation.findIndex((el) => el.id === id);
      state.userVacation.splice(i, 1);
    },
    resetUserVacation(state) {
      state.userVacation = [];
    },
    setUserExtraVacations(state, s) {
      state.userExtraVacations = s;
    },
    addUserExtraVacation(state, s) {
      state.userExtraVacations.unshift(s);
    },
    resetUserExtraDays(state) {
      state.userExtraVacations = [];
    },
    setUserOptions(state, userOptions) {
      state.userOptions = userOptions;
    },
    addUser(state, user) {
      state.users.push(user);
    },
  },
  actions: {
    async loadUsers({ commit }) {
      try {
        const query = new Parse.Query(Parse.User);
        query.greaterThanOrEqualTo('employeeId', 0);
        query.addAscending('employeeId');
        query.limit(1000);
        const response = await query.find(null);

        const uo = [];

        for (let i = 0; i < response.length; i++) {
          const u = response[i];
          uo.push({
            name: `${u.get('firstname')} ${u.get('lastname')} (${u.get('employeeId')})`,
            value: u.id,
          });
        }
        commit('setUserOptions', uo);
        commit('setUsers', response);
      } catch (e) {
        console.log(e);
      }
    },
    async loadUserVacation({ commit }, user) {
      try {
        const query = new Parse.Query('VacationRequest');
        query.equalTo('user', user);
        query.addDescending('createdAt');
        const requests = await query.find(null);
        commit('setUserVacation', requests);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteVacationRequest({ commit }, id) {
      try {
        await Parse.Cloud.run('deleteVacationRequest', { id }, {});
        commit('deleteUserVacation', id);
      } catch (e) {
        console.log(e);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async cancelVacationRequest({}, id) {
      try {
        await Parse.Cloud.run('cancelVacationRequest', { id }, {});
      } catch (e) {
        console.log(e);
      }
    },
    async loadUserExtraVacations({ commit }, user) {
      try {
        const query = new Parse.Query('ExtraVacationDays');
        query.equalTo('user', user);
        query.addDescending('createdAt');
        const requests = await query.find(null);
        commit('setUserExtraVacations', requests);
      } catch (e) {
        console.log(e);
      }
    },
    async addNewUserExtraVacation({ commit }, payload) {
      try {
        let obj = new Parse.Object('ExtraVacationDays');
        obj = await obj.save(payload, null);
        commit('addUserExtraVacation', obj);
      } catch (error) {
        console.log(error);
      }
    },
    async addUser({ commit }, userData) {
      const user = await Parse.Cloud.run('createUser', { userData }, {});
      commit('addUser', user);
    },
    async updateUser({ commit }, { user, cUser }) {
      const updatedUser = await Parse.Cloud.run('updateUser', { user, cUserId: cUser.id }, {});
      commit('updateUser', updatedUser);
    },
  },
  getters: {
    users: (state) => state.users,
    tagesberichtUsers: (state) => state.users.filter((el) => el.get('hasTagesbericht') && el.get('isActive')),
    userVacation: (state) => state.userVacation,
    userExtraVacations: (state) => state.userExtraVacations,
    userOptions: (state) => state.userOptions,
  },
};

export default AdminModule;
