import Parse from 'parse';

const NotificationModule = {
  namespaced: true,
  state: {
    notifications: [],
    limit: 15,
    lastFetched: null,
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
  },
  actions: {
    async loadNotifications({ commit, state }) {
      try {
        const query = new Parse.Query('Notification');
        query.limit(state.limit);
        query.equalTo('user', Parse.User.current());
        query.addDescending('createdAt');
        const noti = await query.find(null);
        commit('setLastFetch', new Date());
        commit('setNotifications', noti);
      } catch (e) {
        commit('setNotifications', []);
      }
    },
  },
  getters: {
    notifications: (state) => state.notifications,
  },
};
export default NotificationModule;
