import { createApp } from 'vue';
import { IonicVue, IonPage } from '@ionic/vue';
import { createI18n } from 'vue-i18n';
import messages from './locales';
import initCall from './plugins/init.js';
import initParse from './plugins/parse';
import mitt from 'mitt';
import VCalendar from 'v-calendar';
import { createHead } from '@vueuse/head';
import { SplashScreen } from '@capacitor/splash-screen';
import './assets/tailwind.css';
import './assets/calendar.scss';
/* IONIC CSS */
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './assets/custom.css';

import router from './router';
import store from './store';

import App from './App.vue';

import ionicModalController from './ionic-components/modal';
import ionicToastController from './ionic-components/toast';

import moment from 'moment';
import 'moment/dist/locale/de';

// datepicker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
SplashScreen.show().then((r) => console.log);
// Vue select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
const config = {
  IS_EKKKO: import.meta.env.VITE_IS_EKKKO === 'true',
  JS_KEY: import.meta.env.VITE_JS_KEY,
  APP_ID: import.meta.env.VITE_APP_ID,
  SERVER_URL: import.meta.env.VITE_SERVER_URL,
  TENANT_SERVICE_uRL: import.meta.env.VITE_TENANT_SERVICE_URL,
  PDFCONV_SERVICE_URL: import.meta.env.VITE_PDFCONV_SERVICE_URL,
  CAPTION_IMAGE_URL: import.meta.env.VITE_CAPTION_IMAGE_URL,
  NODE_ENV: import.meta.env.DEV ? 'development' : 'production',
};

const parseConfig = initParse(config);

const i18n = createI18n({
  locale: 'de-DE',
  fallbackLocale: 'en-US',
  legacy: false,
  messages,
});
const head = createHead();

const app = createApp(App).use(IonicVue).use(store).use(head).use(router).use(i18n).use(VCalendar, {});
app.component('DatePicker', Datepicker);
app.component('VSelect', vSelect);
app.component('IonPage', IonPage);
app.config.globalProperties.emitter = mitt();
if (!parseConfig.error) {
  app.config.globalProperties.$parse = parseConfig.Parse;
}
const filters = {
  formatDate: (date) =>
    Intl.DateTimeFormat(
      !parseConfig.error && parseConfig.Parse.User.current()
        ? parseConfig.Parse.User.current().get('language')
        : 'en-US',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
    ).format(new Date(date)),
};
const language =
  !parseConfig.error && parseConfig.Parse.User.current()
    ? parseConfig.Parse.User.current().get('language').split('-')[0]
    : 'en';
moment.locale(language);
app.config.globalProperties.$router = router;
app.config.globalProperties.$toast = ionicToastController;
app.config.globalProperties.$modal = ionicModalController;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$config = config;
app.config.globalProperties.$filters = filters;

router.isReady().then(async () => {
  await initCall(store);
  app.mount('#app');
  SplashScreen.hide().then((r) => console.log);
});
