import Parse from 'parse';

const LibraryModule = {
  namespaced: true,
  state: {
    library: [],
  },
  mutations: {
    setLibrary(state, library) {
      state.library = library;
    },
    addLink(state, linkLibrary) {
      state.library.push(linkLibrary);
    },
    updateLink(state, updatedLink) {
      const ind = state.library.findIndex((el) => el.id === updatedLink.id);
      state.library.splice(ind, 1, updatedLink);
    },
    deleteLink(state, linkToDelete) {
      const ind = state.library.findIndex((el) => el.id === linkToDelete.id);
      state.library.splice(ind, 1);
    },
  },
  actions: {
    async loadLibrary({ commit }) {
      try {
        const library = await Parse.Cloud.run('loadLibrary', {}, {});
        commit('setLibrary', library);
      } catch (e) {
        console.log(e);
      }
    },
    async addLinkToLibrary({ commit }, { link, desc }) {
      let linkLibrary = new Parse.Object('Library');
      linkLibrary.set('link', link);
      linkLibrary.set('description', desc);
      linkLibrary.set('groups', []);
      try {
        linkLibrary = await linkLibrary.save();
        commit('addLink', linkLibrary);
      } catch (e) {
        console.log(e);
      }
    },
    async updateLinkToLibrary({ commit }, { link, desc, entry }) {
      let updatedLink = entry;
      updatedLink.set('link', link);
      updatedLink.set('description', desc);
      try {
        updatedLink = await updatedLink.save();
        commit('updateLink', updatedLink);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteLink({ commit }, { entry }) {
      try {
        entry = await entry.destroy();
        commit('deleteLink', entry);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    library: (state) => state.library,
  },
};
export default LibraryModule;
