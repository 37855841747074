import Parse from 'parse';
import { hexToRgb } from '@/js/color-helper';

const CompanyStyleModule = {
  namespaced: true,
  state: {
    logoLight: null,
    logoDark: null,
    companyStyle: {},
  },
  mutations: {
    setCompanyStyle(state, { companyStyle, logoDark, logoLight }) {
      const root = document.querySelector(':root');

      for (const [key, value] of Object.entries(companyStyle)) {
        root.style.setProperty(`--${key}`, value);
      }
      state.logoLight = logoLight;
      state.logoDark = logoDark;
      state.companyStyle = companyStyle;
    },
  },
  actions: {
    async loadColors({ commit }) {
      try {
        const config = await Parse.Cloud.run('getParseConfig', {}, null);
        const companyStyle = config.attributes.companyStyle;
        companyStyle.onPrimary = hexToRgb(companyStyle.primary);
        companyStyle.onSideMenu = hexToRgb(companyStyle.sideMenuBackground, true);
        companyStyle.isSideMenuDark = hexToRgb(companyStyle.sideMenuBackground) !== 'white';
        commit('setCompanyStyle', {
          companyStyle,
          logoDark: config.attributes.logo_dark,
          logoLight: config.attributes.logo_light,
        });
        localStorage.setItem(
          'companyColor',
          JSON.stringify({
            companyStyle,
            logoDark: config.attributes.logo_dark,
            logoLight: config.attributes.logo_light,
          })
        );
        return companyStyle;
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    logoDark: (state) => state.logoDark,
    logoLight: (state) => state.logoLight,
    companyStyle: (state) => state.companyStyle,
    socialBusinessName: (state) => state.companyStyle.landingPage,
    backToSocialBusinessName: (state) => state.companyStyle.backToLandingPage,
    groupsPage: (state) => state.companyStyle.groupsPage,
  },
};
export default CompanyStyleModule;
