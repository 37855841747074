import Parse from 'parse';

const FreigeberModule = {
  namespaced: true,
  state: {
    vacationRequests: [],
    lastFetched: null,
  },
  mutations: {
    SET_VACATION_REQUESTS(state, requests) {
      state.vacationRequests = requests;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
  },
  actions: {
    async loadVacationRequests({ commit }, showAll = false) {
      try {
        const response = await Parse.Cloud.run('getVacationOfGroupsOfFreigeber', { showAll }, null);
        commit('SET_VACATION_REQUESTS', response);
        commit('setLastFetch', new Date());
      } catch (e) {
        commit('SET_VACATION_REQUESTS', null);
      }
    },
  },
  getters: {
    vacationRequestsOfGroups: (state) => state.vacationRequests,
    lastFetched: (state) => state.lastFetched,
  },
};
export default FreigeberModule;
