import Parse from 'parse'

const UserModule = {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
  },
  actions: {
    async loadUsers({commit}) {
      try {
        const users = await Parse.Cloud.run('loadUserOverview', {}, {})
        commit('setUsers', users)
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    users: (state) => state.users,
    currentName: (state) => state.users.find((el) => el.id === Parse.User.current().id).name,
  },
}

export default UserModule;
