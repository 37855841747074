const adminSideMenuItems = [
  {
    name: 'staff',
    routeName: 'admin-user',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'VACATION',
    img: '',
  },
  {
    name: 'calendar',
    routeName: 'admin-calendar',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'VACATION',
    img: '',
    doReplace: true,
  },
  /*
  {
    name: 'calendar_events',
    routeName: 'admin-cal-events',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'VACATION',
    img: '',
  },

   */
  {
    name: 'year_calendar',
    routeName: 'admin-employee-year-calendar',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'VACATION',
    img: '',
  },
  {
    name: 'performance_recorder',
    routeName: 'admin-daily-report',
    icon: '',
    permission: [ {Admin: true}, {Leistungserfasser: true}],
    roles: ['Leistungserfasser', 'Admin'],
    feature: 'DISPO',
    img: '',
    doReplace: true,
  },
  {
    name: 'planner',
    routeName: 'admin-dispoplaner',
    icon: '',
    permission: [ {Admin: true}, {Dispo: true}],
    roles: ['Dispo', 'Admin'],
    feature: 'DISPO',
    img: '',
    doReplace: true,
  },
  {
    name: 'todos',
    routeName: 'admin-hrtodos',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'DISPO',
    img: '',
  },
  {
    name: 'deputy_ruling',
    routeName: 'admin-deputy',
    icon: '',
    permission: [ {Admin: true}],
    roles: [ 'Admin'],
    feature: 'VACATION',
    img: '',
  },
  {
    name: 'vac_requests',
    routeName: 'admin-past-vacation',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'VACATION',
    img: '',
  },
  {
    name: 'reports',
    routeName: 'admin-reports',
    icon: '',
    permission: [ {Admin: true}, {Personaler: true}],
    roles: ['Personaler', 'Admin'],
    feature: 'TOOLS',
    img: '',
  },
  {
    name: 'import_data',
    routeName: 'admin-data-import',
    icon: '',
    permission: [ {Admin: true}],
    roles: [ 'Admin'],
    feature: 'VACATION',
    img: '',
  },
  {
    name: 'manage_groups',
    routeName: 'admin-manage-groups',
    icon: '',
    permission: [ {Admin: true}],
    roles: [ 'Admin'],
    feature: 'SOCIAL',
    img: '',
  },
  {
    name: 'manage_link',
    routeName: 'admin-library',
    icon: '',
    permission: [ {Admin: true}],
    roles: [ 'Admin'],
    feature: 'SOCIAL',
    img: '',
  },
  {
    name: 'annual_financial_statement',
    routeName: 'admin-end-year',
    icon: '',
    permission: [ {Admin: true}],
    roles: ['Admin'],
    feature: 'VACATION',
    extraConditions: [
      {
        store: 'parse-config/config',
        storeProp: 'doEndYearFor',
        operator: 2,
        value: 0,
      }
    ],
    img: '',
  },
  {
    name: 'back',
    routeName: 'social-business',
    roles: [ 'Admin', 'Personaler', 'Dispo', 'Leistungserfasser'],
    icon: '',
    img: '',
    doReplace: true,
  }
];

export default adminSideMenuItems;
