import Parse from 'parse';

const PostsModule = {
  namespaced: true,
  state: {
    posts: [],
    pinnedPosts: [],
    limit: 15,
    offset: 15,
    lastFetched: null,
    imageFile: null,
    scrollToPost: null,
    groupPost: [],
  },
  mutations: {
    setPosts(state, posts) {
      state.posts = posts;
    },
    setScrollToPost(state, scrollToPost) {
      state.scrollToPost = scrollToPost;
    },
    setGroupPosts(state, groupPosts) {
      state.groupPosts = groupPosts;
    },
    setImageFile(state, file) {
      state.imageFile = file;
    },
    setPinnedPosts(state, pinnedPosts) {
      state.pinnedPosts = pinnedPosts;
    },
    increaseOffset(state) {
      state.offset = state.offset + state.limit;
    },
    updatePost(state, { post, type = 'posts' }) {
      const posts = state[type];

      const p = posts.filter((el) => el.id === post.id)[0];

      if (p) {
        const index = posts.indexOf(p);
        posts[index] = post;
        state[type] = posts;
      }
    },
    removePost(state, post) {
      if (state.posts) {
        state.posts = state.posts.filter((el) => el.id !== post.id);
      }
      if (state.groupPosts) {
        state.groupPosts = state.groupPosts.filter((el) => el.id !== post.id);
      }
    },
    insertOwnPost(state, post) {
      state.posts.unshift(post);
    },
    insertNewPosts(state, posts) {
      for (let i = posts.length - 1; i >= 0; i--) {
        state.posts.unshift(posts[i]);
      }
    },
    addToPinned(state, post) {
      state.pinnedPosts.unshift(post);

      const index = state.posts.map(el => el.id).indexOf(post.id);
      state.posts.splice(index, 1);
    },
    removeFromPinned(state, post) {
      const index = state.pinnedPosts.map(el => el.id).indexOf(post.id);
      state.pinnedPosts.splice(index, 1);
      if (!state.posts.map((el) => el.id).includes(post.id)) {
        state.posts.unshift(post);
      }
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
  },
  actions: {
    async loadPosts({ commit, state }) {
      try {
        const query = new Parse.Query('Post');
        query.limit(state.limit);
        query.addDescending('createdAt');
        query.include('author');
        query.include('thread');
        query.include('feessbookGroups');
        query.equalTo('isPinned', false);
        query.equalTo('active', true);
        const posts = await query.find(null);
        commit('setLastFetch', new Date());
        commit('setPosts', posts);
      } catch (e) {
        commit('setPosts', null);
      }
    },
    async loadMorePosts({ commit, state }) {
      try {
        const query = new Parse.Query('Post');
        query.addDescending('createdAt');
        query.include('author');
        query.include('thread');
        query.include('feessbookGroups');
        query.limit(state.limit);
        query.skip(state.offset);
        query.equalTo('isPinned', false);
        const posts = await query.find(null);
        commit('setPosts', [...state.posts, ...posts]);
        commit('increaseOffset');
        return posts;
      } catch (e) {
        console.log(e);
      }
    },
    async loadPinnedPosts({ commit }) {
      try {
        const query = new Parse.Query('Post');
        query.equalTo('isPinned', true);
        query.include('author');
        query.include('thread');
        query.include('feessbookGroups');
        const pinnedPosts = await query.find(null);
        commit('setPinnedPosts', pinnedPosts);
        return pinnedPosts;
      } catch (e) {
        commit('setPinnedPosts', null);
      }
    },
    async fetchNewPosts({ state, commit }) {
      try {
        const query = new Parse.Query('Post');
        query.include('author');
        query.include('thread');
        query.include('feessbookGroups');
        query.addDescending('createdAt');
        query.equalTo('isPinned', false);
        query.notEqualTo('author', Parse.User.current());
        query.greaterThanOrEqualTo('createdAt', state.lastFetched);
        const posts = await query.find(null);
        commit('setLastFetch', new Date());
        if (posts.length) commit('insertNewPosts', posts);
      } catch (e) {
        commit('insertNewPosts', []);
      }
    },
    deletePost({ commit }, post) {
      commit('removePost', post);
    },
  },
  getters: {
    posts: (state) => state.posts,
    pinnedPosts: (state) => state.pinnedPosts,
    lastFetched: (state) => state.lastFetched,
    imageFile: (state) => state.imageFile,
    groupPosts: (state) => state.groupPosts,
    scrollToPost: (state) => state.scrollToPost,
  },
};
export default PostsModule;
