<template>
  <IonApp class="bg-contentBackground">
    <IonRouterOutlet />
  </IonApp>
</template>

<script setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useHead } from '@vueuse/head';
import { getCurrentInstance } from 'vue';
const app = getCurrentInstance();
let head;
if (app.appContext.config.globalProperties.$config.IS_EKKKO) {
  head = {
    title: 'EKKKO ',
    link: [
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon/ekkkoFavicon.png',
      },
    ],
  };
} else {
  head = {
    title: 'FEESS - Die App',
    link: [
      { rel: 'icon', type: 'apple-touch-icon', href: '/favicon/apple-touch-icon.png' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon/favicon-32x32.png' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon/favicon-16x16.png' },
      { rel: 'manifest', href: '/favicon/site.webmanifest' },
      { rel: 'icon', type: 'shortcut icon', href: '/favicon/favicon.ico' },
    ],
  };
}
useHead(head);
</script>
