import Parse from 'parse';

const FeessbookGroupsModule = {
  namespaced: true,
  state: {
    feessbookGroups: [],
    feessbookGroupsData: [],
    workingGroups: [],
    myFeessbookGroups: [],
  },
  mutations: {
    setFeessbookGroups(state, feessbookGroups) {
      state.feessbookGroups = feessbookGroups;
    },
    setFeessbookGroupsData(state, data) {
      state.feessbookGroupsData = data;
    },
    addFeessbookGroup(state, feessbookGroup) {
      state.feessbookGroups.push(feessbookGroup);
      state.feessbookGroups.sort((a, b) => a.get('name').localeCompare(b.get('name')));
    },
    editFeessbookGroup(state, editedFeessbookGroup) {
      const indexOfEdited = state.feessbookGroups.map((group) => group.id).indexOf(editedFeessbookGroup.id);
      state.feessbookGroups[indexOfEdited] = editedFeessbookGroup;
    },
    setWorkingGroups(state, workingGroups) {
      state.workingGroups = workingGroups;
    },
    setMyFeessbookGroups(state, myFeessbookGroups) {
      state.myFeessbookGroups = myFeessbookGroups;
    },
  },
  actions: {
    async loadfeessbookGroups({ commit }) {
      try {
        const groups = await new Parse.Query('FeessbookGroup')
          .addAscending('name')
          .include('users')
          .include('workingGroups')
          .limit(3000)
          .find();
        commit('setFeessbookGroups', groups.slice());
      } catch (e) {
        console.log(e);
      }
    },
    async addOrEditFeessbookGroupObject({ commit }, obj) {
      try {
        const tuple = await Parse.Cloud.run('addOrEditFeessbookGroup', obj);
        obj.id ? commit('editFeessbookGroup', tuple) : commit('addFeessbookGroup', tuple);
      } catch (err) {
        console.log(err);
      }
    },
    async loadWorkingGroups({ commit }) {
      try {
        const workingGroups = await new Parse.Query('WorkingGroup').addAscending('name').limit(3000).find();
        commit('setWorkingGroups', workingGroups);
      } catch (e) {
        console.log(e);
      }
    },
    async loadMyFeessbookGroups({ commit }) {
      const group = Parse.User.current().get('feessbookGroups');
      for (let i = 0; i < group.length; i++) {
        await group[i].fetch();
      }
      commit('setMyFeessbookGroups', group);
    },
  },
  getters: {
    feessbookGroups: (state) => state.feessbookGroups,
    workingGroups: (state) => state.workingGroups,
    myFeessbookGroups: (state) => state.myFeessbookGroups,
  },
};
export default FeessbookGroupsModule;
