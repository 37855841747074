import Parse from 'parse';

const WorkingGroupsModule = {
  namespaced: true,
  state: {
    workingGroups: [],
  },
  mutations: {
    setWorkingGroups(state, workingGroups) {
      state.workingGroups = workingGroups;
    },
    addWorkingGroup(state, workingGroups) {
      state.workingGroups.unshift(workingGroups);
    },
    updateWorkingGroup(state, updatedWorkingGroup) {
      const ind = state.workingGroups.findIndex((el) => el.id === updatedWorkingGroup.id);
      state.workingGroups.splice(ind, 1, updatedWorkingGroup);
    },
  },
  actions: {
    async loadWorkingGroups({ commit }) {
      console.log('workingGroups');
      try {
        const wg = await Parse.Cloud.run('loadWorkingGroupsForDeputyOverview', {}, {});
        commit('setWorkingGroups', wg);
      } catch (e) {
        console.log(e);
      }
    },
    async addWkGroup(_, { workingGroup }) {
      await Parse.Cloud.run('addWKG', { newWKG: workingGroup }, {});
    },
    async updateWKGroup(_, { editedWkGroup, groupId }) {
      await Parse.Cloud.run('updateWKG', { group: editedWkGroup, groupId }, {});
    },
  },
  getters: {
    workingGroups: (state) => state.workingGroups,
  },
};
export default WorkingGroupsModule;
