import Parse from 'parse';

const CommentLikesModule = {
  namespaced: true,
  state: {
    likedComments: [],
    unlikedComments: [],
    likesCount: {},
  },
  getters: {
    getLikes: (state) => state.likedComments,
    getUnlikes: (state) => state.unlikedComments,
    getLikeCount: (state) => state.likesCount,
  },
  mutations: {
    addLike: (state, id) => state.likedComments.push(id),
    removeLike: (state, id) => state.likedComments.splice(state.likedComments.indexOf(id), 1),
    addUnlike: (state, id) => state.unlikedComments.push(id),
    removeUnlike: (state, id) => state.unlikedComments.splice(state.unlikedComments.indexOf(id), 1),
    insertLikeCount: (state, obj) => state.likesCount[obj.key] =  obj.count,
    increaseLikeCount: (state, key) => state.likesCount[key]++,
    decreaseLikeCount: (state, key) => state.likesCount[key]--,
  },
  actions: {
    async opinionToDatabase({ state, commit }, obj) {
      if (obj.opinion) {
        try {
          await Parse.Cloud.run('likeComment', { commentId: obj.id });
          commit('addLike', obj.id);
          commit('removeUnlike', obj.id);
          commit('increaseLikeCount', '_' + obj.id);
        } catch (err) {
          console.log(`${err.name} : ${err.message}`);
        }
      } else {
        try {
          await Parse.Cloud.run('unlikeComment', { commentId: obj.id });
          commit('removeLike', obj.id);
          commit('addUnlike', obj.id);
          commit('decreaseLikeCount', '_' + obj.id);
        } catch (err) {
          console.log(`${err.name} : ${err.message}`);
        }
      }
    },
    async checkLike({ state, commit }, id) {
      try {
        const query = new Parse.Query('Comment');
        const comment = await query.get(id);
        const likes = comment.get('commentLikes');
        const commentLikes = await likes.query().find();
        const liked = commentLikes.find((el) => el.get('author').id === Parse.User.current().id);
        if (liked) commit('addLike', id);
        else commit('addUnlike', id);
        commit('insertLikeCount', { key: '_' + id, count: commentLikes.length });
      } catch (err) {
        console.log(`${err.name} : ${err.message}`);
      }
    },
  },
};
export default CommentLikesModule;
