import Parse from 'parse';

const ParseConfigModule = {
  namespaced: true,
  state: {
    config: null,
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
  },
  actions: {
    async loadConfig({ commit }) {
      try {
        const response = await Parse.Cloud.run('getParseConfig', {}, null);
        commit('setConfig', response.attributes);
      } catch (e) {
        commit('setConfig', null);
      }
    },
  },
  getters: {
    config: (state) => state.config,
  },
};
export default ParseConfigModule;
