import Parse from 'parse';

const ShortenVacModule = {
  namespaced: true,
  state: {
    shortenVacs: [],
  },
  mutations: {
    setShortenVacs(state, vacs) {
      state.shortenVacs = vacs;
    },
    addShortenVac(state, vac) {
      state.shortenVacs.unshift(vac);
    },
  },
  actions: {
    async loadShortenVac({ commit }, user) {
      try {
        const vacs = await new Parse.Query('ShortenVac')
          .equalTo('user', user)
          .equalTo('year', new Date().getFullYear())
          .addAscending('createdAt')
          .limit(2000)
          .find();
        commit('setShortenVacs', vacs);
      } catch (e) {
        console.log(e);
      }
    },
    async addShortenVac({ commit }, attr) {
      try {
        const shortenVac = new Parse.Object('ShortenVac');
        attr.year = new Date().getFullYear();
        const sVac = await shortenVac.save(attr);
        commit('addShortenVac', sVac);
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  getters: {
    shortenVacs: (state) => state.shortenVacs,
  },
};
export default ShortenVacModule;
