export function hexToRgb(hex, notBlackWhite = false) {
  const hexCode = hex.charAt(0) === '#' ? hex.substr(1, 6) : hex;

  const hexR = parseInt(hexCode.substr(0, 2), 16);
  const hexG = parseInt(hexCode.substr(2, 2), 16);
  const hexB = parseInt(hexCode.substr(4, 2), 16);
  // Gets the average value of the colors
  const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

  const darkColor = notBlackWhite ? '#404040' : 'black';
  const lightColor = 'white';

  return contrastRatio >= 0.5 ? darkColor : lightColor;
}

export const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
  '#e2fcef',
  '#9b287b',
  '#5c164e',
  '#402039',
  '#170f11',
  '#f4f1de',
  '#e07a5f',
  '#3d405b',
  '#81b29a',
  '#f2cc8f',
  '#d9bbf9',
  '#cca7a2',
  '#aa9fb1',
  '#7871aa',
  '#4e5283',
  '#f55d3e',
  '#878e88',
  '#f7cb15',
  '#76bed0',
  '#fab3a9',
  '#c6ad94',
  '#7fb285',
  '#463239',
  '#ed6b86',
  '#dde8b9',
  '#e8d2ae',
  '#d7b29d',
  '#cb8589',
  '#796465',
  '#d7fff1',
  '#aafcb8',
  '#a410b8',
  '#8cd790',
  '#77af9c',
  '#285943',
  '#d4c5e2',
  '#c9d7f8',
  '#a7e2e3',
  '#80cfa9',
  '#4c6663',
  '#86cb92',
  '#71b48d',
  '#404e7c',
  '#251f47',
];
