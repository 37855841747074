import _ from 'lodash';

/**
 * To use this mixin please use the spread operator to extend your state, mutations, actions, getters
 *
 * import { multiEntityLoading } from '../mixins/loading-states';
 * const Module = {
 *    namespaced: true,
 *    state: {
 *        ...multiEntityLoading.state,
 *        ...,
 *    },
 *    mutations: {
 *        ...multiEntityLoading.mutations,
 *        ...,
 *    },
 * };
 */

const loadingStatus = {
  NOT_LOADED: 0,
  LOADING: 1,
  LOADED: 2,
};

export const singleEntityLoading = {
  state: {
    isLoading: loadingStatus.NOT_LOADED,
  },
  mutations: {
    setLoading(state) {
      state.isLoading = loadingStatus.LOADING;
    },
    setNotLoaded(state) {
      state.isLoading = loadingStatus.NOT_LOADED;
    },
    setLoaded(state) {
      state.isLoading = loadingStatus.LOADED;
    },
  },
  actions: {},
  getters: {
    isNotLoaded: (state) => state.isLoading === loadingStatus.NOT_LOADED,
    isLoading: (state) => state.isLoading === loadingStatus.LOADING,
    isLoaded: (state) => state.isLoading === loadingStatus.LOADED,
  },
};

export const multiEntityLoading = {
  state: {
    isLoading: {},
  },
  mutations: {
    resetLoadingState(state) {
      state.isLoading = {};
    },
    setLoading(state, entity) {
      state.isLoading = _.assign({}, state.isLoading, {
        [entity]: loadingStatus.LOADING,
      });
    },
    setLoaded(state, entity) {
      state.isLoading = _.assign({}, state.isLoading, {
        [entity]: loadingStatus.LOADED,
      });
    },
    setNotLoaded(state, entity) {
      state.isLoading = _.assign({}, state.isLoading, {
        [entity]: loadingStatus.NOT_LOADED,
      });
    },
  },
  actions: {
    handleLoadingState({ commit }, { entity, promise }) {
      commit('setLoading', entity);
      return new Promise((resolve, reject) => {
        promise
          .then((response) => {
            commit('setLoaded', entity);
            resolve(response);
          })
          .catch((error) => {
            commit('setNotLoaded', entity);
            reject(error);
          });
      });
    },
  },
  getters: {
    isNotLoaded: (state) => (entity) => !state.isLoading[entity], // if it is 0 or unset
    isLoading: (state) => (entity) => state.isLoading[entity] === loadingStatus.LOADING,
    isLoaded: (state) => (entity) => state.isLoading[entity] === loadingStatus.LOADED,
  },
};
