import Parse from 'parse';

const EmployeeModule = {
  namespaced: true,
  state: {
    vacationDays: null,
  },
  mutations: {
    setVacationDays(state, days) {
      state.vacationDays = days;
    },
  },
  actions: {
    async getVacationDays({ commit }, userId = null) {
      const date = new Date();
      const month = date.getMonth();
      const year = new Date().getFullYear();
      try {
        const days = await Parse.Cloud.run('getVacationDays', { month, year, userId }, {});
        commit('setVacationDays', days);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    vacationDays: (state) => state.vacationDays,
  },
};
export default EmployeeModule;
