import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';
import { Parse } from 'parse';
export default {
  async checkConnection() {
    try {
      return await Network.getStatus();
    } catch (err) {
      throw new Error(this.errorMessage('checkConnection', err));
    }
  },
  async checkFilesystem() {
    // Returns an empty array if the directory is empty or doesn't exist yet, otherwise it contains the file-names as strings
    try {
      const collections = await Filesystem.readdir({
        path: 'images/',
        directory: Directory.Data,
      });
      return collections.files;
    } catch (err) {
      return [];
    }
  },
  writeTextOnImage ({text, imageUrl}) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");
      const image = new Image(); // Using optional size for image


      // Load an image of intrinsic size 300x227 in CSS pixels
      image.src = 'data:image/png;base64, ' + imageUrl;
      image.onload = drawImageActualSize; // Draw when image has loaded
      function drawImageActualSize() {
        // Use the intrinsic size of image in CSS pixels for the canvas element
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        // Will draw the image as 300x227, ignoring the custom size of 60x45
        // given in the constructor
        ctx.drawImage(this, 0, 0);

        // To use the custom size we'll have to specify the scale parameters
        // using the element's width and height properties - lets draw one
        // on top in the corner:
        const fontSize = this.naturalHeight * 0.03;
        ctx.font = fontSize + "px Arial";
        ctx.shadowColor="black";
        ctx.shadowBlur=4;
        ctx.fillText(text, fontSize, this.naturalHeight - fontSize);
        ctx.shadowBlur=0;
        ctx.lineWidth=5;
        ctx.fillStyle="white";
        ctx.fillText(text, fontSize, this.naturalHeight - fontSize);
        const result = canvas.toDataURL();
        resolve(result);
      }
    })
  },
  async createParseFileObjectsWithAcl(data) {
    const parseFileObjects = [];
    for (let i = 0; i < data.images.length; i++) {
      const file = data.images[i];
      let nn = '';

      if (data.name) {
        nn += data.name + '_';
      }
      nn += `${data.date}_${data.timeStamp}_${Parse.User.current().get('employeeId')}_${i + 1}.${file.format}`;
      file.data = { base64: file.base64String };
      try {
        let parseFile = new Parse.File(nn, file.data);
        parseFile = await parseFile.save();
        let fileObject = new Parse.Object('File');
        fileObject.set('file', parseFile);
        fileObject.set('name', nn);
        fileObject.set('size', file.data.base64.length);
        fileObject.set('type', 'image');
        fileObject.setACL(data.acl);
        fileObject = await fileObject.save();
        parseFileObjects.push(fileObject);

      } catch (err) {
        throw new Error(this.errorMessage('createParseFileObjectsWithACL', err));
      }
    }
    return parseFileObjects;
  },
  async getNumberForNewCollection() {
    // Returns the number from the name of the latest file plus 1, or just 1 if there aren't any.
    const collections = await this.checkFilesystem();
    if (collections.length) {
      const number = collections[collections.length - 1].slice(9, 11);
      return number[1] === '.' ? Number(number[0]) + 1 : Number(number) + 1;
    } else {
      return 1;
    }
  },
  async onUpload(fileData) {
    try {
      localStorage.setItem('construction', fileData.construction.value.id);
      const acl = new Parse.ACL(Parse.User.current());
      let parseFileObjects;
      const dataForParse = this.formatDataForParse(fileData, acl);

      if (fileData.addCaption) {
        // In this case the image-array is sent to the docker container first, to add the caption.
        dataForParse.images = await this.sendToCaptionService(fileData.images, fileData.captionURL);
        parseFileObjects = await this.createParseFileObjectsWithAcl(dataForParse);
      } else {
        parseFileObjects = await this.createParseFileObjectsWithAcl(dataForParse);
      }

      const obj = new Parse.Object('ConstructionSubmitRequest');
      await obj.save({
        user: Parse.User.current(),
        files: parseFileObjects,
        construction: fileData.construction.value,
      });

      // The collectionName is returned to the method 'uploadImagesFromFilesystem' so the file can be deleted.
      if (fileData.collectionName) return fileData.collectionName;
    } catch (e) {
      // If the object doesn't have a collectionName, it needs to be saved to the filesystem.
      if (!fileData.collectionName) await this.savePictures(fileData);
      throw new Error(this.errorMessage('onUpload', e));
    }
  },
  async savePictures(imageObj) {
    // Creates the directory if it doesn't exist yet.
    delete imageObj.vueFile;
    const collectionNumber = await this.getNumberForNewCollection();
    imageObj.collectionName = `Sammlung_${collectionNumber}.txt`;
    try {
      await Filesystem.writeFile({
        path: `images/${imageObj.collectionName}`,
        data: JSON.stringify(imageObj),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });
    } catch (err) {
      throw new Error(this.errorMessage('savePictures', err));
    }
  },
  async selectPicture(camera) {
    // Returns a picture just taken or selected from the device as base64-string.
    try {
      return await Camera.getPhoto({
        quality: 100,
        resultType: CameraResultType.Base64,
        source: camera ? CameraSource.Camera : CameraSource.Photos,
      });
    } catch (err) {
      throw new Error(this.errorMessage('selectPicture', err));
    }
  },
  async sendToCaptionService(images) {
    try {
    // Adds the positions for the caption and sends the image-array to the docker-container.
    const jsonArray = images.slice();
    const resultArray = [];
    for (let i = 0; i < jsonArray.length; i++) {
      const image = jsonArray[i];
      const result = await this.writeTextOnImage({imageUrl: image.base64String, text: image.caption});
      const obj = {
        base64String: result,
        format: jsonArray[0].format,
      }
      resultArray.push(obj);
      /*
      jsonArray[i].xAxis = 'right';
      jsonArray[i].yAxis = 'bottom';

       */
    }
      return resultArray;
      /*
      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonArray),
      });
      return await res.json();

       */

    } catch (err) {
      throw new Error(this.errorMessage('sendToCaptionService', err));
    }
  },
  async uploadImagesFromFilesystem(fileNames) {
    try {
      const allCollections = [];
      // The strings in the fileNames-array are the names of the files in the filesystem.
      for (const name of fileNames) {
        const file = await Filesystem.readFile({
          path: `images/${name}`,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });

        const collection = JSON.parse(file.data);
        const Baustellen = Parse.Object.extend('Baustellen');
        collection.construction.value = new Baustellen(collection.construction.value);
        allCollections.push(collection);
      }

      // Receives the name of the file to delete it if the upload was successful.
      for (const collection of allCollections) {
        const fileName = await this.onUpload(collection);
        if (fileName) {
          await Filesystem.deleteFile({
            path: `images/${fileName}`,
            directory: Directory.Data,
          });
        }
      }
    } catch (err) {
      throw new Error(this.errorMessage('uploadImagesFromFilesystem', err));
    }
  },
  formatDataForParse(fileData, acl) {
    return {
      images: fileData.images,
      acl,
      name: fileData.name,
      date: fileData.date,
      timeStamp: fileData.timeStamp,
    };
  },
  errorMessage(method, eMessage) {
    return 'In ' + method + ': ' + eMessage;
    // return `Source: ${this.path}\nMethod: ${method}\n${eMessage}`;
  },
  path: '@/js/MobileImageUploads.js',
};
