<template>
  <ion-item class="relative">
    <ion-label class="text-grayDark" position="stacked"> {{ placeholder }}</ion-label>
    <ion-input
      :value="modelValue"
      :step="step"
      class="input-classes"
      :type="type"
      :min="min"
      :debounce="debounce"
      @ionInput="$emit('update:modelValue', $event.srcElement.value)"
      @ionChange="$emit('change', $event)"
    />
    <button
      v-if="showMiniButton"
      class="absolute text-sm right-0 bottom-2.5 hover:underline text-primary pl-2 z-10 font-bold"
      @click="$emit('miniButtonClick')"
    >
      {{ miniButtonText }}
    </button>
  </ion-item>
</template>

<script>
import { IonLabel, IonItem, IonInput } from '@ionic/vue';
export default {
  name: 'FEInput',
  components: { IonLabel, IonItem, IonInput },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Date],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: 'dark',
    },
    step: {
      type: String,
      default: '1',
    },
    min: {
      type: Number,
      default: undefined,
    },
    showMiniButton: {
      type: Boolean,
      default: false,
    },
    miniButtonText: {
      type: String,
      default: 'button',
    },
  },
  emits: ['update:modelValue', 'change'],
  methods: {},
};
</script>

<style scoped></style>
