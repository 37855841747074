import Parse from 'parse';
const DocumentPoolModule = {
  namespaced: true,
  state: {
    folder: [],
    files: [],
    tags: [],
    search: {
      files: [],
      folder: [],
    }
  },
  mutations: {
    setFolder(state, folder) {
      state.folder = folder;
    },
    setSearchResult(state, result) {
      state.search = result;
    },
    deleteFolder(state, folderToDelete) {
      const ind = state.folder.findIndex((el) => el.id === folderToDelete.id);
      state.folder.splice(ind, 1);
    },
    setFiles(state, files) {
      state.files = files;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    addTag(state, tag) {
      state.tags.push(tag);
    }
  },
  actions: {
    async addFilesToFolder({ dispatch }, {files, currentFolder, tags}) {
      try {
        for (let i = 0; i < files.length; i++) {
            const currentFile = files[i];
            let type;
            const fileExtension = currentFile.type.split('/')[1];
            switch (currentFile.type.split('/')[1]) {
                case 'jpg':
                    type = 'image';
                    break;
                  case 'jpeg':
                    type = 'image';
                    break;
                  case 'png':
                    type = 'image';
                    break;
                  case 'txt':
                    type = 'txt';
                    break;
                  case 'pdf':
                    type = 'pdf';
                    break;
                  default:
                    type = 'image';
                }
            const name = currentFile.name;
            const parentFolder = currentFolder;
            const fileName = name.split('.')[0].replace(/\W/ig, '') + '.' + fileExtension;
            const parseFile = await new Parse.File(fileName, currentFile).save();
            const parseObjectFile = new Parse.Object('File');
            parseObjectFile.set('file', parseFile);
            parseObjectFile.set('name', fileName);
            parseObjectFile.set('type', type);
            parseObjectFile.set('tags', tags);
            parseObjectFile.set('parentFolder', parentFolder);
            await parseObjectFile.save();
            await dispatch('document-pool/loadFolder', parentFolder, { root: true });
          }
        } catch (e) {
          console.log(e);
        }
    },
    async addFolder({ dispatch }, { name, currentFolder, auth, tags }) {
      let folder = new Parse.Object('Folder');
      folder.set('name', name);
      folder.set('auth', auth);
      folder.set('tags', tags);
      if (!currentFolder) {
        folder.set('level', 1);
      } else folder.set('parentFolder', currentFolder);
      try {
        folder = await folder.save();
        dispatch('loadFolder', currentFolder);
      } catch (e) {
        console.log(e);
      }
    },
    async addTag({commit}, {name}) {
      const tag = await new Parse.Object('Tag').set('name', name).save();
      commit('addTag', tag);
    },
    async deleteFolder({  }, { fol }) {
      try {
        await Parse.Object.destroyAll(fol);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteFile({  }, file) {
      try {
        await file.destroy();
      } catch (e) {
        console.log(e);
      }
    },
    async loadFolder({ commit }, currentFolder) {
      try {
        const currentFolderId = currentFolder ? currentFolder.id : null;
        const {files, folder} = await Parse.Cloud.run('loadFolderAndFilesForCurrentFolder', {currentFolderId}, {});
        commit('setFiles', files);
        commit('setFolder', folder);
      } catch (e) {
        console.log(e);
      }
    },
    async loadClickedFolder({ commit }, { selFol }) {
      try {
        const query = new Parse.Query('Folder');
        query.equalTo('parentFolder', selFol);
        const folder = await query.find();
        commit('setFolder', folder);
      } catch (e) {
        console.log(e);
      }
    },
    async loadTags({ commit }) {
      try {
        const tagQuery = await new Parse.Query('Tag').find();
        commit('setTags',tagQuery);
      } catch (e) {
        console.log(e);
      }
    },
    async searchFolder({commit}, search) {
      try {
        const result = await Parse.Cloud.run('searchFolderAndFiles', {search: search.toLowerCase().replace(/\s+/g, '')}, {})
        commit('setSearchResult', result);
      } catch(e) {
        console.log(e);
      }
    }
  },
  getters: {
    folder: (state) => state.folder,
    files: (state) => state.files,
    tags: (state) => state.tags,
    searchFolder: (state) => state.search.folder,
    searchFiles: (state) => state.search.files,
  }
};
export default DocumentPoolModule;
