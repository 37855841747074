import { createStore } from 'vuex';

import AuthModule from '@/store/modules/auth';
import StoreModules from '@/store/store-modules';
import ActiveMessageModule from '@/store/modules/active-message';
import AdminModule from '@/store/modules/admin';
import AdminCalendarModule from '@/store/modules/admin-calendar';
import ApprovedVacationRequestsModule from '@/store/modules/approved-vacation-requests';
import CalendarEventEntriesModule from '@/store/modules/calendar-event-entries';
import CommentLikesModule from '@/store/modules/comment-likes';
import CompanyStyleModule from '@/store/modules/company-style';
import ConfirmerCalendar from '@/store/modules/confirmer-calendar';
import ConstructionModule from '@/store/modules/construction';
import DailyReportModule from '@/store/modules/daily-report';
import DeputyModule from '@/store/modules/deputy';
import DispoPlanerModule from '@/store/modules/dispo-planer';
import EmployeeModule from '@/store/modules/employee';
import EmployeesOnVacationModule from '@/store/modules/employees-on-vacation';
import FeessbookGroupsModule from '@/store/modules/feessbook-groups';
import FreigeberModule from '@/store/modules/freigeber';
import GroupPostsModule from '@/store/modules/group-posts';
import HolidaysModule from '@/store/modules/holidays';
import HrTodosModule from '@/store/modules/hr-todos';
import InstallationModule from '@/store/modules/installation';
import LibraryModule from '@/store/modules/library';
import MachineDiaryModule from '@/store/modules/machine-diary';
import NotificationModule from '@/store/modules/notifications';
import ParseConfigModule from '@/store/modules/parse-config';
import PostDetailModule from '@/store/modules/post-detail';
import PostsModule from '@/store/modules/posts';
import ResizeModule from '@/store/modules/resize';
import SetFocusModule from '@/store/modules/set-focus';
import ShortenVacModule from '@/store/modules/shorten-vac';
import VacationRequestModule from '@/store/modules/vacation-request';
import WorkingGroupsModule from '@/store/modules/working-groups';
import WorkingHoursModule from '@/store/modules/working-hours';
import YearCalendarModule from '@/store/modules/year-calendar';
import DocumentPoolModule from '@/store/modules/document-pool';
import TagModule from '@/store/modules/tag';
import UserModule from '@/store/modules/user';
import TicketModule from '@/store/modules/ticket';

const modules = {
  [StoreModules.AUTH]: AuthModule,
  [StoreModules.ACTIVEMESSAGE]: ActiveMessageModule,
  [StoreModules.ADMIN]: AdminModule,
  [StoreModules.ADMINCALENDAR]: AdminCalendarModule,
  [StoreModules.APPROVEDVACATIONREQUESTS]: ApprovedVacationRequestsModule,
  [StoreModules.CALENDAREVENTENTRIES]: CalendarEventEntriesModule,
  [StoreModules.COMMENTLIKES]: CommentLikesModule,
  [StoreModules.COMPANYSTYLE]: CompanyStyleModule,
  [StoreModules.CONFIRMERCALENDAR]: ConfirmerCalendar,
  [StoreModules.CONSTRUCTION]: ConstructionModule,
  [StoreModules.DAILYREPORT]: DailyReportModule,
  [StoreModules.DEPUTY]: DeputyModule,
  [StoreModules.DISPOPLANER]: DispoPlanerModule,
  [StoreModules.EMPLOYEE]: EmployeeModule,
  [StoreModules.EMPLOYEESONVACATION]: EmployeesOnVacationModule,
  [StoreModules.FEESSBOOKGROUPS]: FeessbookGroupsModule,
  [StoreModules.FREIGEBER]: FreigeberModule,
  [StoreModules.GROUPPOSTS]: GroupPostsModule,
  [StoreModules.HOLIDAYS]: HolidaysModule,
  [StoreModules.HRTODOS]: HrTodosModule,
  [StoreModules.INSTALLATION]: InstallationModule,
  [StoreModules.LIBRARY]: LibraryModule,
  [StoreModules.MACHINEDIARY]: MachineDiaryModule,
  [StoreModules.NOTIFICATIONS]: NotificationModule,
  [StoreModules.PARSECONFIG]: ParseConfigModule,
  [StoreModules.POSTDETAIL]: PostDetailModule,
  [StoreModules.POSTS]: PostsModule,
  [StoreModules.RESIZE]: ResizeModule,
  [StoreModules.SETFOCUS]: SetFocusModule,
  [StoreModules.SHORTENVAC]: ShortenVacModule,
  [StoreModules.VACATIONREQUEST]: VacationRequestModule,
  [StoreModules.WORKINGGROUPS]: WorkingGroupsModule,
  [StoreModules.WORKINGHOURS]: WorkingHoursModule,
  [StoreModules.YEARCALENDAR]: YearCalendarModule,
  [StoreModules.DOCUMENTPOOL]: DocumentPoolModule,
  [StoreModules.TAGMODULE]: TagModule,
  [StoreModules.USER]: UserModule,
  [StoreModules.TICKET]: TicketModule,
};

export default new createStore({
  modules,
});
