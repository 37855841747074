<template>
  <div>
    <div v-for="item in adminOrNormalSideMenuItems" :key="item.name" class="cursor-pointer" @click="onItem(item)">
      <div
        v-if="checkPermissions(item.roles) && checkAttributes(item.attributes) && checkFeatures(item.feature) && checkExtraConditions(item.extraConditions)"
        :class="`${item.class ? item.class : ''} menu-entry`"
      >
        <div class="menu-entry-inner">
          <i class="material-icons text-sideMenuForeground">{{ item.icon }}</i>
          <div class="flex items-center">
            <div
              v-if="item.name === 'vacation' && $store.getters['vacation-request/requestsToConfirm'].length > 0"
              class="bg-primary rounded-full mr-2 w-6 h-6 flex justify-center items-center text-onPrimary text-sm"
            >
              {{ $store.getters['vacation-request/requestsToConfirm'].length }}
            </div>

            <p :class="`${isActiveRoute(item.routeName) ? 'text-primary' : 'text-sideMenuForeground'} `">
              {{ checkConfig(item) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminSideMenuItems from "../../config/admin-sidemenu-items.js";
import sideMenuItems from "../../config/sidemenu-items.js";
import { Dialog } from '@capacitor/dialog';
export default {
  name: 'SidemenuContent',
  components: {},
  props: {
    isInAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sideMenuItems,
      adminSideMenuItems
    };
  },
  computed: {
    adminOrNormalSideMenuItems() {
      if(this.isInAdmin) {
        return this.adminSideMenuItems;
      } else {
        return this.sideMenuItems;
      }
    }
  },
  mounted() {},
  unmounted() {},
  methods: {
    onItem(item) {
      if(item.doReplace) {
        this.$router.replace({ name: item.routeName });
      } else {
        this.$router.push({ name: item.routeName });
      }
    },
    checkConfig(i) {
      if (i.name === 'socialBusiness') {
        return this.$store.getters['company-style/socialBusinessName'];
      } else if (i.name === 'social_groups') {
        return this.$store.getters['company-style/groupsPage'] || this.$t('groups');
      } else return this.$t(i.name);
    },
    isActiveRoute(routeName) {
      if (routeName === 'index') {
        return this.$route.path === '/';
      } else {
        return this.$route.name.includes(routeName);
      }
    },
    toggleSidebarAction() {
      //this.$store.dispatch('drawer/toggleSidebarOff');
    },
    async onLogout() {
      const { value } = await Dialog.confirm({
        title: 'Logout',
        message: `Wirklich ausloggen?`,
      });

      if (value) {
        await this.$store.dispatch('auth/logout');
        await this.$router.push('/login');
      }
    },
    checkPermissions(permissions) {
      /*
      let granted = true;
      if(!permissions) {
        return granted;
      }

      const allowedPerm = permissions.filter(el => el[Object.keys(el)[0]])
      console.log(allowedPerm);
      const notAllowedPerm = permissions.filter(el => !el[Object.keys(el)[0]])
      console.log(notAllowedPerm)

      let isAllowed = true;
      let isNotAllowed = true;

      for (const item of allowedPerm) {
        for (const [key, value] of Object.entries(item)) {
          if (this.$store.getters['auth/roles'].includes(key)) {
            if (!value) {
              isAllowed = false;
              break;
            } else {
              isAllowed = true;
            }
          }
        }
      }
      for (const item of notAllowedPerm) {
        for (const [key, value] of Object.entries(item)) {
          if (this.$store.getters['auth/roles'].includes(key)) {
            if (!value) {
              isNotAllowed = false;
              break;
            } else {
              isNotAllowed = true;
            }
          }
        }
      }
      console.log(isNotAllowed && isAllowed)
      return granted;

       */
      const myRoles = this.$store.getters['auth/roles'];
      return myRoles.some(el => permissions.includes(el));
    },
    checkAttributes(attributes) {
      let granted = true;

      if (!attributes) return granted;

      for (const item of attributes) {
        for (const [key, value] of Object.entries(item)) {
          if (this.$parse.User.current().get(key) === value) {
            granted = true;
            break;
          } else {
            granted = false;
          }
        }
      }
      return granted;
    },
    checkFeatures(feature) {
      if (!this.$config.IS_EKKKO || !feature) {
        return true;
      }
      const features = localStorage.getItem('tenant-data-features');
      return features.includes(feature);
    },
    checkExtraConditions(conds) {
      if(!conds) return true;
      let granted = true;
      for(let i = 0; i < conds.length; i++) {
        const store = conds.store;
        const prop = conds.storeProp;
        const operator = conds.operator;
        const value = conds.value;
        let condsStoreValue = this.$store.getters[store];
        if(prop) {
          condsStoreValue = condsStoreValue[prop];
        }
        switch (operator){
          case 1:
            granted = condsStoreValue === value;
            break;
          case 2:
            granted = condsStoreValue !== value;
            break;
          case 3:
            granted = condsStoreValue > value
            break;
          case 4:
            granted = condsStoreValue < value
            break;
        }
        if(!granted) {
          return granted;
        }
      }
    }
  },
};
</script>

<style scoped>
.menu-entry {
  @apply flex w-full flex-row h-10 justify-between items-center px-4 my-2 transition-all duration-500 ease-in-out hover:opacity-50;
}

.menu-entry-inner {
  @apply flex flex-row justify-between items-center w-full;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
