function getDateFromNowAddWeeks(weeks) {
  const date = new Date();
  const newMonth = date.getMonth() + parseInt(weeks);
  date.setMonth(newMonth);
  return date;
}
function getStartAndEndDate(dates) {
  let daysToSort = [...dates];
  daysToSort = daysToSort.map((el) => {
    if (el.date) {
      return el.date;
    } else return el;
  });
  const sortedDates = daysToSort.sort((a, b) => a.getTime() - b.getTime());
  return [
    {
      start: sortedDates[0],
      end: sortedDates[sortedDates.length - 1],
    },
  ];
}

export default { getDateFromNowAddWeeks, getStartAndEndDate };
