import Parse from 'parse';
export default async function (store) {
  if (!Parse.applicationId) return;
  if(Parse.User.current()) {
    await store.dispatch('auth/loadUser');
  }
  if (Parse.applicationId) await store.dispatch('company-style/loadColors');
  if (store.getters['auth/currentUser']) {
    await store.dispatch('posts/loadPosts');
    await store.dispatch('auth/loadFreigeberGroups');
    await store.dispatch('posts/loadPinnedPosts');
    await store.dispatch('installation/loadInstallation');
    await store.dispatch('notifications/loadNotifications');
    await store.dispatch('vacation-request/loadRequestsToConfirm');
    await store.dispatch('feessbook-groups/loadfeessbookGroups');
    await store.dispatch('feessbook-groups/loadMyFeessbookGroups');
    await store.dispatch('parse-config/loadConfig');
    await store.dispatch('active-message/loadActiveMessagePosts');
    await store.dispatch('calendar-event-entries/loadCalendarEvents');
  }
}
