const sideMenuItems = [
  {
    name: 'socialBusiness',
    routeName: 'social-business',
    icon: 'face',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'SOCIAL',
    img: 'social-business',
  },
  {
    name: 'vacation',
    routeName: 'vacation',
    icon: 'assignment',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'VACATION',
    img: 'vacation',
  },
  {
    name: 'working_group_calendar',
    routeName: 'calendar',
    icon: 'calendar_view_day',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'VACATION',
    img: 'working-group-calendar',
  },
  {
    name: 'social_groups',
    routeName: 'groups',
    icon: 'groups',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'SOCIAL',
    img: 'social-groups',
  },
  {
    name: 'notifications',
    routeName: 'notifications',
    icon: 'alarm',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'SOCIAL',
    img: 'notifications',
  },
  {
    name: 'construction_images',
    routeName: 'construction-images',
    icon: 'camera',
    class: 'hide-on-desktop',
    permission: [{ Extern: true }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin', 'Extern'],
    feature: 'TOOLS',
    img: 'construction-images',
  },
  {
    name: 'machine_diary',
    routeName: 'machine-diary',
    icon: 'local_shipping',
    permission: [{ Extern: true }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin', 'Extern'],
    feature: 'TOOLS',
    img: 'machine-diary',
  },
  {
    name: 'document_pool',
    routeName: 'document-pool',
    route: '/document-pool/',
    icon: 'folder',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin', 'Extern'],
    feature: 'TOOLS',
    img: 'document-pool',
  },
  {
    name: 'library',
    routeName: 'library',
    icon: 'bookmarks',
    permission: [{ Extern: true }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin', 'Extern'],
    feature: 'SOCIAL',
    img: 'library',
  },
  {
    name: 'working_hours',
    routeName: 'working-hours',
    icon: 'schedule',
    permission: [{ Extern: false }, { User: true }],
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    attributes: [{ vacationType: 'SoKa-Bau' }],
    feature: 'TOOLS',
    img: 'working-hours',
  },
  /*
  {
    name: 'to_do',
    routeName: 'to-do',
    icon: 'rule',
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'SOCIAL',
    img: '',
  },
  */
  {
    name: 'admin',
    routeName: 'admin',
    icon: 'admin_panel_settings',
    permission: [{ Extern: false }, { Admin: true }],
    roles: ['Admin', 'Leistungserfasser', 'DISPO'],
    justDesktop: true,
    img: '',
  },
  {
    name: 'profile',
    routeName: 'profile',
    icon: 'portrait',
    roles: ['User', 'Urlaubsplaner', 'Dispo', 'Leistungserfasser', 'Personaler', 'Redakteur', 'Admin'],
    feature: 'SOCIAL',
    justDesktop: true,
    img: 'w',
  },
];

export default sideMenuItems;
