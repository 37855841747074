import Parse from 'parse';

export default function initParse(config) {
  const appId = localStorage.getItem('tenant-data-app-id');
  const jsKey = localStorage.getItem('tenant-data-js-key');
  const serverUrl = localStorage.getItem('tenant-data-server-url');
  if (!config.IS_EKKKO) {
    Parse.initialize(config.APP_ID, config.JS_KEY);
    Parse.serverURL = config.SERVER_URL;
    return { Parse, error: false };
  } else if (appId && serverUrl) {
    Parse.initialize(appId, jsKey);
    Parse.serverURL = serverUrl;
    return { Parse, error: false };
  } else {
    return { Parse, error: true };
  }
}
