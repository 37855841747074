export default {
  accept: 'Accept',
  accepted_from: 'Accepted from',
  add_calendar_event: 'Add event',
  add_event_for_user: 'Create event for user',
  analytics: 'Analytics',
  calendar: 'Calendar',
  calendar_events: 'Calendar Events',
  social_groups: 'Social Groups',
  close: 'Close',
  add_folder: 'Add folder',
  new_folder_name: 'New folder name',
  create_new_folder: 'Create new folder',
  upload_file: 'Upload file',
  create_docPool: 'Create',
  file_download: 'Download file',
  search: 'Search...',
  employee_number: 'Employee Number',
  search_filter: 'Search',
  employee_tooltip: 'Here you can add and search for employees',
  data_import_tooltip: 'Here you can import remaining vacations using a csv file',
  library_tooltip: 'Here you can create links for specific themes/websites',
  export_tooltip: 'The overview can be displayed up to a certain month in order to adjust vacation days if necessary',
  social_tooltip: 'You can add and manage social business groups in here',
  wkg_tooltip: 'You can add and manage working groups in here',
  employee_year_calendar_tooltip: 'A function to have the vacation days of a specific employee at a glance',
  daily_report_tooltip: 'Here you can set the performance of certain employees from groups',
  dispo_tooltip:
    'A specially created overview for dispatch planners to determine the performance of employees. In addition, good hours and vacation days for each employee are visible here',
  hr_tooltip:
    'The comments of the MRP planning are created here. Visible here is: Creator, date, comment, creation date, employee number and the status (completed or not)',
  deputy_tooltip: 'This function allows you to add or delete substitutes for specific groups',
  comment: 'Comment',
  create_new_comment: 'Create a comment ...',
  days: 'Days',
  document_pool: 'Document pool',
  upload_document: 'Upload document',
  add_comment: 'Add comment...',
  final_comment: 'close comments',
  days_left: 'Days left',
  days_taken: 'Days taken',
  working_hours: 'Working hours',
  decline: 'Decline',
  quarantine: 'Quarantine',
  confirmed_by: 'confirmed by',
  library: 'Library',
  declined_from: 'Declined from',
  delete: 'delete',
  edit: 'Edit',
  education_company: 'Inter-company training',
  education_vacation: 'Training leave',
  email: 'E-mail',
  employee: 'Employee',
  employees_on_vacation: 'Employees on vacation',
  end: 'End',
  error_max_file_upload_size: 'File size has to be smaller than {data}.',
  error_mime_type_not_allowed: 'File has to be {data}.',
  feessbook: 'Feessbook',
  flex_time: 'Flexitime adjustment / Overtime reduction',
  freetime_616: 'Leave of absence with pay according to §616 BGB',
  go_back: 'Go back',
  group: 'Group',
  half_day: 'Half day',
  hide: 'Hide',
  history_request: 'Processed vacation requests',
  holi_day: 'Holiday',
  last_updated: 'Last updated: {date}',
  like: 'Like',
  liked: 'Like',
  likes: 'Like|Likes',
  loading: 'Loading ...',
  login: 'Login',
  logout: 'Logout',
  menu: 'Menu',
  no_entries: 'No entries',
  no_more_requests: 'No more requests',
  notifications: 'Notifications',
  open_requests: 'Open requests',
  open_vacation_requests: 'Open vacation confirmations',
  parenting_time: 'Parental leave',
  parseserver_error_101: 'Wrong credentials.',
  parseserver_error_119: 'You do not have the permission to do that.',
  parseserver_error_1: 'Ups, something went wrong.',
  parseserver_error_200: 'Username / Password required',
  parseserver_error_202: 'User with this E-Mail exists already.',
  password: 'Password',
  pinned_posts: 'Pinned Posts',
  post: 'Post',
  post_placeholder: 'Whats new...',
  posting: 'Posting ...',
  pull_to_refresh: 'Pull to refresh',
  reason: 'Reason',
  release_to_load: 'Release to load',
  remember_me: 'Remember me',
  request_created_at: 'Request created at',
  save: 'Save',
  school: 'Vocational school',
  search_employees: 'Search employees ...',
  select: 'Please select',
  select_working_group: 'Select a group',
  send: 'Send',
  settings: 'Settings',
  show_all: 'Show all',
  show_more: 'More',
  sick_with_money: 'Sick with pay',
  sick_without_money: 'Sick without pay',
  special_vacation: 'Special leave',
  start: 'Start',
  still_open: 'Still open',
  submit: 'Submit',
  upload_media: 'Upload media',
  username: 'Username',
  vacation: 'Vacation',
  vacation_request: 'Vacation request',
  vacation_requests_starting_soon: 'Vacation requests starting soon',
  vacation_without_money: 'Vacation without pay',
  viewed_by: 'Viewed by',
  visible_for: 'Visible for:',
  welcome: 'Welcome',
  working_group_calendar: 'Teamkalender',
  workinggroup_calendar: 'Teamkalender',
  confirm: 'Confirm',
  declined: 'Declined',
  cancel: 'Cancel',
  deleten: 'delete',
  canceln: 'cancel',
  groups: 'Groups',
  my_groups: 'My groups',
  my_vacation_overview: 'My vacation overview',
  open: 'Open',
  accepted: 'Accepted',
  canceled: 'storniert',
  construction_images: 'Construction Images',
  select_construction: 'Select construction',
  event_was_added: 'Event was added',
  admin: 'Admin',
  machine_diary: 'Machine diary',
  select_machine: 'Select machine',
  open_vacation: 'Open vacation',
  workspace: 'Workspace',
  no_notifications_yet: 'No notifications yet ...',
  sick_report_exist: 'Notification of illness submitted',
  update_successful: 'Update successful',
  maternity_protection: 'Maternity protection',
  quarantine_without_money: 'Quarantine without continued payment of wages',
  quarantine_with_money: 'Quarantine with continued payment of wages',
  pin_this_post: 'Pin this post',
  delete_this_post: 'Delete this post',
  create_calendar_event: 'Create calendar event',
  update_calendar_event: 'Update calendar event',
  translation: 'Translation',
  calendar_event_background: 'Background color',
  calendar_event_color: 'Text color',
  extra: 'Extra',
  create: 'Create',
  key: 'Key',
  preview: 'Preview',
  search_calendar_event: 'Search for calendar event',
  german: 'German',
  english: 'English',
  example: 'Example',
  next: 'Next',
  not_translated: 'not translated',
  profile: 'Account',
  load_more: 'Mehr laden',
  log_out: 'Log Out',
  active: 'Active',
  inactive: 'Inactive',
  vacationdays: 'Vacation days',
  sokabau: 'SoKa-Bau',
  vacation_type: 'Vacation type',
  general: 'General',
  misc: 'More informations',
  updated_request: 'Updated request',
  enter_reason: 'Enter reason',
  select_two_days: 'Please select 2 dates',
  to_do: 'To-do lists',
  leaving_date: 'Left the company at:',
  workinggroup: 'Department|Departments',
  press_enter_to_search: 'Press Enter to search',
  i_like: 'Like',
  dont_like_it: "Don't like",
  upload_profileimage: 'Upload profile picture',
  camera: 'Camera ...',
  photo_library: 'Choose from camera roll ...',
  delete_profile_image: 'Delete profile image',
  reset_password_admin_hint: 'The password will be set to the birthdate of the user. (YYYYMMDD)',
  reset_password: 'Reset password',
  passwort_reset_successful: 'Password was set successfully',
  invalid_birthdate: 'Invalid birthdate',
  yes: 'Yes',
  password_reset_confirm: 'Do you really want to reset the password?',
};
