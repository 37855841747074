import Parse from 'parse';

const DeputyModule = {
  namespaced: true,
  state: {
    deputy: null,
  },
  mutations: {
    setDeputy(state, deputy) {
      state.deputy = deputy;
    },
  },
  actions: {
    async addDeputy({ commit }, deputyID) {
      try {
        const dep = await Parse.Cloud.run('addDeputy', { deputyID }, {});
        commit('setDeputy', dep);
      } catch (e) {
        console.log(e);
      }
    },
    async loadDeputy({ commit }) {
      try {
        const workingGroup = await new Parse.Query('WorkingGroup')
          .include('deputy')
          .equalTo('chef', Parse.User.current())
          .first();
        commit('setDeputy', workingGroup.get('deputy'));
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    deputy: (state) => state.deputy,
  },
};
export default DeputyModule;
