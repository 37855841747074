import Parse from 'parse';
import { multiEntityLoading } from '@/store/mixins/loading-states';

const ConstructionModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    constructions: [],
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setConstructions(state, constructions) {
      state.constructions = constructions;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadConstructions({ commit, dispatch }) {
      try {
        const query = new Parse.Query('Baustellen');
        query.limit(5000);
        query.equalTo('type', 'Auftrag');
        query.notEqualTo('statusBOQ', 'Abgeschlossen');
        query.notEqualTo('statusBOQ', 'Schlussgerechnet');
        query.addAscending('no');

        const con = await dispatch('handleLoadingState', {
          entity: 'constructions',
          promise: query.find(null),
        });
        commit('setConstructions', con);
      } catch (e) {
        console.log(e);
        commit('setConstructions', []);
      }
    },
  },

  getters: {
    ...multiEntityLoading.getters,
    constructions: (state) => state.constructions,
  },
};
export default ConstructionModule;
