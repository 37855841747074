<template>
  <IonPage>
    <ion-split-pane v-if="isDesktop" class="desktop-view" content-id="main-content">
      <div class="menu py-10 px-10 flex flex-col overflow-auto">
        <UserInfoBox />
        <div
          class="flex flex-col pb-6 element-shadow bg-sideMenuBackground rounded-lg mb-8"
          :class="isInAdmin ? '' : 'pt-4'"
        >
          <div v-if="isInAdmin" class="w-full bg-decline rounded-t-lg p-2">
            <p class="text-white font-bold text-xl text-center">{{ $t('admin_section') }}</p>
          </div>
          <SidemenuContent :is-in-admin="isInAdmin" />
        </div>
        <div class="flex justify-center mb-10 w-full h-full">
          <img alt="LOGO" class="object-contain h-8" :src="logoImage" />
        </div>
      </div>
      <ion-router-outlet id="main-content" :animated="false"></ion-router-outlet>
    </ion-split-pane>
    <ion-tabs v-if="!isDesktop" color="light" @ion-tabs-will-change="ionTabsWillChange">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="h-16">
        <ion-tab-button tab="SocialBusiness" href="/social-business">
          <ion-icon :icon="activeTab === 'SocialBusiness' ? peopleCircle : peopleCircleOutline"></ion-icon>
          <ion-text>{{ $store.getters['company-style/socialBusinessName'] }}</ion-text>
        </ion-tab-button>
        <ion-tab-button tab="Vacation" href="/vacation">
          <ion-icon :icon="activeTab === 'Vacation' ? reader : readerOutline"></ion-icon>
          <ion-text>{{ $t('vacation') }}</ion-text>
        </ion-tab-button>
        <ion-tab-button tab="Notifications" href="/notifications">
          <ion-icon :icon="activeTab === 'Notifications' ? alarm : alarmOutline"></ion-icon>
          <ion-text>{{ $t('notifications') }}</ion-text>
        </ion-tab-button>
        <ion-tab-button tab="Menu" href="/menu">
          <ion-icon :icon="activeTab === 'Menu' ? apps : appsOutline"></ion-icon>
          <ion-text>{{ $t('menu') }}</ion-text>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </IonPage>
</template>

<script lang="ts" setup>
import {
  apps,
  appsOutline,
  alarm,
  alarmOutline,
  reader,
  readerOutline,
  peopleCircle,
  peopleCircleOutline,
} from 'ionicons/icons';
import { IonPage, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonSplitPane, IonText } from '@ionic/vue';
import SidemenuContent from '../components/molecules/SidemenuContent.vue';
import { App } from '@capacitor/app';
import MobileImageUploads from '../js/MobileImageUploads.js';
import { useWindowSize } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import Parse from 'parse';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import UserInfoBox from '../components/molecules/UserInfoBox.vue';
import 'v-calendar/dist/style.css';
const route = useRoute();

const store = useStore();
const { width } = useWindowSize();
App.addListener('appStateChange', async (state) => {
  if (state.isActive) {
    await store.dispatch('posts/loadPosts');
    await store.dispatch('posts/loadPinnedPosts');
    try {
      const files = await MobileImageUploads.checkFilesystem();
      if (files.length && (await MobileImageUploads.checkConnection()).connected)
        await MobileImageUploads.uploadImagesFromFilesystem(files);
    } catch (err) {
      // this.$toast.error(err);
    }
  }
});

const i18n = useI18n();
if (Parse.User.current()) {
  i18n.locale.value = Parse.User.current().get('language');
}
const activeTab = ref('SocialBusiness');
const isInAdmin = computed(() => route.fullPath.includes('admin'));

const isDesktop = computed(() => {
  return width.value > 1024;
});

const ionTabsWillChange = (ev) => {
  activeTab.value = ev.tab;
};

const logoImage = computed(() => {
  if (store.getters['company-style/companyStyle'].isSideMenuDark) {
    return store.getters['company-style/logoLight'].url();
  }
  return store.getters['company-style/logoDark'].url();
});
</script>

<style scoped>
.desktop-view {
  @apply flex flex-row;
  display: none;
}
@media (min-width: 1024px) {
  ion-tabs {
    display: none !important;
  }
  .desktop-view {
    display: flex;
  }
}
.menu {
  min-width: 350px;
  max-width: 350px;
  font-size: 17px;
  font-weight: bold;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menu::-webkit-scrollbar {
  display: none;
}

.logout-button {
  @apply bg-primary text-onPrimary rounded-lg w-full p-3 text-center cursor-pointer  hover:opacity-50 transition duration-500;
}
</style>
