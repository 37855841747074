const StoreModules = {
  AUTH: 'auth',
  ACTIVEMESSAGE: 'active-message',
  ADMIN: 'admin',
  ADMINCALENDAR: 'admin-calendar',
  APPROVEDVACATIONREQUESTS: 'approved-vacation-requests',
  CALENDAREVENTENTRIES: 'calendar-event-entries',
  COMMENTLIKES: 'comment-likes',
  COMPANYSTYLE: 'company-style',
  CONFIRMERCALENDAR: 'confirmer-calendar',
  CONSTRUCTION: 'construction',
  DAILYREPORT: 'daily-report',
  DEPUTY: 'deputy',
  DISPOPLANER: 'dispo-planer',
  TAGMODULE: 'tag',
  EMPLOYEE: 'employee',
  EMPLOYEESONVACATION: 'employees-on-vacation',
  FEESSBOOKGROUPS: 'feessbook-groups',
  FREIGEBER: 'freigeber',
  GROUPPOSTS: 'group-posts',
  HOLIDAYS: 'holidays',
  HRTODOS: 'hr-todos',
  INSTALLATION: 'installation',
  LIBRARY: 'library',
  MACHINEDIARY: 'machine-diary',
  NOTIFICATIONS: 'notifications',
  PARSECONFIG: 'parse-config',
  POSTDETAIL: 'post-detail',
  POSTS: 'posts',
  REASONSFORABSENCE: 'reasons-for-absence',
  RESIZE: 'resize',
  SETFOCUS: 'set-focus',
  SHORTENVAC: 'shorten-vac',
  VACATIONREQUEST: 'vacation-request',
  WORKINGGROUPS: 'working-groups',
  WORKINGHOURS: 'working-hours',
  YEARCALENDAR: 'year-calendar',
  DOCUMENTPOOL: 'document-pool',
  USER: 'user',
  TICKET: 'ticket',
};

export default StoreModules;
