<template>
  <ion-page color="primary">
    <ion-content color="primary">
      <div
        :class="`h-full w-screen flex flex-col justify-center items-center px-3 ${
          $config.IS_EKKKO ? 'bg-contentBackground' : 'bg-sideMenuBackground'
        }`"
      >
        <div class="w-4/5 sm:w-1/2 md:w-1/3 xl:w-1/4 mb-5 flex justify-center items-center">
          <img alt="Company Logo" class="image h-24" :src="logoImage" />
        </div>
        <ion-card class="login-card">
          <ion-card-header>
            <ion-card-title class="text-grayDark text-3xl">
              {{ $t('login') }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <form v-if="step === 1" @submit.prevent="onLoadTenant">
              <FEInput
                v-model="user.workspace"
                class="mb-5"
                mode="light"
                :placeholder="$t('workspace')"
              />
              <button type="submit" :disabled="submitting" class="w-full rounded bg-primary text-onPrimary text-center py-3 font-bold">
                <span v-if="submitting">
                  <IonSpinner color="light" />
                </span>
                <span v-else> {{
                    $t('next')
                  }}</span></button>
            </form>
            <form v-if="step === 2" @submit.prevent="onLogin">

              <FEInput v-model="user.email" mode="light" class="mb-5" type="text" :placeholder="$t('username')" />
              <FEInput
                v-model="user.password"
                class="mb-5"
                type="password"
                mode="light"
                :placeholder="$t('password')"
              />
              <button type="submit" :disabled="submitting" class="w-full rounded bg-primary text-onPrimary text-center py-3 font-bold hover:opacity-50 active:opacity-50">
                <span v-if="submitting">
                  <IonSpinner color="light" />
                </span>
                <span v-else> {{$t('login') }}</span>
               </button>
            </form>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { AppIcon } from '@capacitor-community/app-icon';
import Parse from 'parse';
import imgUrl from '@/assets/images/ci/color.svg';
import { IonContent, IonPage, IonCard, IonCardContent, IonCardTitle, IonCardHeader, IonSpinner } from '@ionic/vue';
import FEInput from '@/components/atoms/FEInput.vue';
export default {
  name: 'LoginPage',
  components: {  FEInput, IonContent, IonPage, IonCard, IonCardContent, IonCardTitle, IonCardHeader, IonSpinner },
  props: {},
  data() {
    return {
      user: {
        email: '',
        password: '',
        workspace: '',
      },
      step: 1,
      submitting: false,
      logoImage: imgUrl,
    };
  },
  computed: {
    isParseInitialized() {
      return !!Parse.applicationId;
    },
  },
  mounted() {
    try {
      this.$i18n.locale = 'de-DE';
      if (this.$route.query.data) {
        const entries = atob(this.$route.query.data.toString()).split(';');
        if (entries.length === 3) {
          this.user.workspace = entries[0];
          this.user.email = entries[1];
          this.user.password = entries[2];
        }
      }
      if(this.isParseInitialized) {
        this.step = 2;
        this.logoImage = this.$store.getters['company-style/logoDark'].url();
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async onLoadTenant() {
      try {
        this.submitting = true;
        await this.$store.dispatch('auth/loadTenantData', this.user.workspace);
        await this.$store.dispatch('company-style/loadColors');
        this.logoImage = this.$store.getters['company-style/logoDark'].url();
        this.step = 2;
        this.$forceUpdate();
      } catch (error) {
        this.onAuthError(error);
      } finally {
        this.submitting = false;
      }

      await AppIcon.change({name: `${this.user.workspace}`, suppressNotification: true});

    },
    async onLogin() {
      if (!this.user.email) {
        await this.$toast.error(this.$t('please_state_mail').toString());
        return;
      }
      if (!this.user.password) {
        await this.$toast.error(this.$t('please_state_password').toString());
        return;
      }
      if (!this.isParseInitialized && !this.user.workspace) {
        await this.$toast.error(this.$t('please_state_workspace').toString());
        return;
      }
      try {
        this.submitting = true;
        //await this.$store.dispatch('company-style/loadColors');
        await this.$store.dispatch('auth/login', this.user);
        window.location.reload();
      } catch (error) {
        this.onAuthError(error);
      } finally {
        this.submitting = false;
      }
    },
    onAuthError(error) {
      if (error.code) {
        this.$toast.error(this.$t('parseserver_error_' + error.code));
      } else {
        this.$toast.error(this.$t(error.message));
      }
    }
  },
};
</script>
<style scoped>
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label::before {
  width: 25px;
  height: 25px;
  border-radius: 10%;
  border: 2px solid #fff;
  display: block;
  content: '';
  float: left;
  margin-right: 5px;
}
input[type='checkbox']:checked + label::before {
  @apply bg-primary;
}
.login-card {
  @apply w-full sm:w-1/2 md:w-1/3 xl:w-1/4;
}
</style>
