import Parse from 'parse'

const TicketModule = {
  namespaced: true,
  state: {
    tickets: [],
    boards: [],
    activeBoard: null,
  },
  mutations: {
    setTickets(state, tickets) {
      state.tickets = tickets
    },
    addTicket(state, ticket) {
      state.tickets.push(ticket)
    },
    removeTicket(state, id) {
      state.tickets = state.tickets.filter((el) => el.object.id !== id)
    },
    updateTicket(state, ticket) {
      const ind = state.tickets.findIndex((el) => el.object.id === ticket.object.id)
      state.tickets.splice(ind, 1, ticket)
    },
    setActiveBoard(state, board) {
      state.activeBoard = board
    },
    setBoards(state, boards) {
      state.boards = boards
    },
    addBoard(state, board) {
      state.boards.push(board)
    },
    updateBoard(state, board) {
      const ind = state.boards.findIndex((el) => el.id === board.id)
      state.boards.splice(ind, 1, board)
    },
    deleteBoard(state, board) {
      state.boards = state.boards.filter((el) => el.id !== board.id)
      state.tickets = []
    },
  },
  actions: {

    async loadTickets({ commit, state }, board) {
      try {
        const tickets = await Parse.Cloud.run('loadTickets', { boardId: board.id }, {})

        commit('setTickets', tickets)
      } catch (e) {
        console.log(e)
      }
    },

    async loadBoards({ commit, dispatch, state }) {
      try {
        const boards = await Parse.Cloud.run('loadBoards', {}, {})
        // const boards = await new Parse.Query('Board').limit(1000).find()
        commit('setBoards', boards)
        if (boards.length > 0) {
          if (state.activeBoard) dispatch('loadTickets', state.activeBoard)
          else dispatch('loadTickets', boards[0])
        }
      } catch (e) {
        console.log(e)
      }
    },

    async createBoard({ commit, dispatch }, name) {
      try {
        const board = new Parse.Object('Board')
        board.set('name', name)
        const newBoard = await board.save()
        commit('addBoard', newBoard)
        commit('setActiveBoard', newBoard)
        dispatch('loadBoards')
      } catch (e) {
        console.log(e)
      }
    },

    async updateBoard({ commit }, { name, board }) {
      try {
        board.name = name;
        await new Parse.Object('Board').save(board);
        commit('updateBoard', board);
      } catch (e) {
        console.log(e)
      }
    },

    async createTicket({ commit }, t) {
      try {
        if (!t.userId) {
          t.userId = []
        }
        t.board = new Parse.Object('Board', { id: t.board.id });
        t.author = Parse.User.current();
        const ticket = await new Parse.Object('Ticket').save(t);
        const o = {}
        o.object = ticket
        o.data = t
        commit('addTicket', o)
      } catch (e) {
        console.log(e)
      }
    },

    async updateTicket({ commit }, { ticket, data }) {
      try {
        const uTicket = await ticket.save(data)
        const o = {}
        o.object = uTicket
        o.data = data
        commit('updateTicket', o)
      } catch (e) {
        console.log(e)
      }
    },

    async deleteTicket({ commit }, ticket) {
      try {
        await ticket.destroy()
        commit('removeTicket', ticket.id)
      } catch (e) {
        console.log(e)
      }
    },

    moveTicket({ commit, state, dispatch }, { itemID, stateNumber }) {
      const ticket = state.tickets.find((el) => el.object.id === itemID)
      if (ticket.data.state === stateNumber) {
        return
      }
      const data = {}
      data.title = ticket.data.title
      data.description = ticket.data.description
      data.userId = ticket.data.userId
      data.state = stateNumber
      data.prio = ticket.data.prio
      dispatch('updateTicket', { ticket: ticket.object, data })
    },

    async moveTicketsFromBoardToBoard({ commit, state, dispatch }, { fromBoard, toBoard, ticketStates }) {
      await Parse.Cloud.run('moveTicketsFromBoardToBoard', { fromBoard, toBoard, ticketStates }, {})
      dispatch('loadBoards')
    },

    async deleteBoard({ commit, dispatch }, board) {
      try {
        await Parse.Cloud.run('deleteBoard', { boardId: board.id }, {})
        commit('deleteBoard', board)
        dispatch('loadBoards')
      } catch (e) {
        console.log(e)
      }
    },
  },
  getters: {
    tickets: (state) => state.tickets,
    boards: (state) => state.boards,
  },
}

export default TicketModule;
