import Parse from 'parse';

const PostDetailModule = {
  namespaced: true,
  state: {
    post: null,
    likes: [],
    viewedBy: [],
  },
  mutations: {
    setPost(state, post) {
      state.post = post;
    },
    setLikes(state, likes) {
      state.likes = likes;
    },
    setViewedBy(state, viewedBy) {
      state.viewedBy = viewedBy;
    },
  },
  actions: {
    async loadPost({ commit, rootState }, postId) {
      let post = rootState.posts.posts.filter((el) => el.id === postId);

      if (post.length > 0) {
        commit('setPost', post[0]);
        return post[0];
      }
      post = rootState.posts.pinnedPosts.filter((el) => el.id === postId);
      if (post.length > 0) {
        commit('setPost', post[0]);
        return post[0];
      }

      const query = new Parse.Query('Post');
      post = await query.get(postId, null);
      commit('setPost', post);
      return post;
    },
    async loadAnalytics({ commit, state }) {
      try {
        const likeQuery = state.post.relation('likes').query().include('author').limit(3000);
        const likes = await likeQuery.find();
        commit('setLikes', likes);
        const viewedByQuery = state.post.relation('viewedBy').query().include('author').limit(3000);
        const viewedBy = await viewedByQuery.find();
        commit('setViewedBy', viewedBy);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
  },
  getters: {
    post: (state) => state.post,
    likes: (state) => state.likes,
    viewedBy: (state) => state.viewedBy,
  },
};
export default PostDetailModule;
