function getFullName(user) {
  return `${user.get('firstname')} ${user.get('lastname')}`;
}

function getInitials(user) {
  return `${user.get('firstname').substr(0, 1)}${user.get('lastname').substr(0, 1)}`;
}

function getColor(user) {
  return user.get('color');
}

export default { getFullName, getInitials, getColor };
