import Parse from 'parse';
import moment from 'moment';

const HolidaysModule = {
  namespaced: true,
  state: {
    holidays: [],
    holidayNames: {},
    lastFetched: null,
    momentHolidays: [],
  },
  mutations: {
    setHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setHolidayNames(state, obj) {
      state.holidayNames = obj;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
    setMomentHolidays(state, m) {
      state.momentHolidays = m;
    },
  },
  actions: {
    async loadHolidays({ commit }, year) {
      try {
        let fullHolidays = [];
        const date = new Date();
        const whichYear = new Date(year).getFullYear() || date.getFullYear();
        const years = [whichYear - 1, whichYear, whichYear + 1];
        for (let i = 0; i < years.length; i++) {
          const apiUrl = 'https://feiertage-api.de/api/?jahr=' + years[i] + '&nur_land=BW';
          const holidays = await fetch(apiUrl).then((response) => {
            if (response.status !== 200) {
              // eslint-disable-next-line no-console
              console.error('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }

            return response.json().then((data) => {
              const dates = data;
              const newArr = [];
              const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
              for (const entry of Object.values(dates)) {
                if (!entry.hinweis) {
                  newArr.push(new Date(entry.datum).toLocaleDateString('de-DE', options).replace(/\./g, '/'));
                }
              }
              return newArr;
            });
          });
          fullHolidays = [...fullHolidays, ...holidays];
        }
        commit('setLastFetch', new Date());
        commit('setHolidays', fullHolidays);

        const holidayNames = [
          'Neujahr',
          'Heilige Drei Könige',
          'Karfreitag',
          'Ostermontag',
          'Tag der Arbeit',
          'Christi Himmelfahrt',
          'Pfingstmontag',
          'Fronleichnam',
          'Tag der deutschen Einheit',
          'Allerheiligen',
          'Erster Weihnachtsfeiertag',
          'Zweiter Weihnachtsfeiertag',
        ];
        const holidayNamesObj = {};
        const holidaysThreeYears = holidayNames.slice().concat(holidayNames.slice()).concat(holidayNames.slice());
        for (let i = 0; i < 36; i++) holidayNamesObj[fullHolidays[i]] = holidaysThreeYears[i];
        commit('setHolidayNames', holidayNamesObj);

        const momentHolidays = [];
        for (let i = 0; i < fullHolidays.length; i++) {
          const d = moment(fullHolidays[i], 'DD-MM-YYYY');
          momentHolidays.push(d);
        }
        commit('setMomentHolidays', momentHolidays);
      } catch (e) {
        commit('setHolidays', []);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async addRemainingHolidays({}, payload) {
      await Parse.Cloud.run('addRemainingHoliday', { data: payload.data, reason: payload.reason }, {});
    },
  },
  getters: {
    holidays: (state) => state.holidays,
    holidayNames: (state) => state.holidayNames,
    momentHolidays: (state) => state.momentHolidays,
  },
};
export default HolidaysModule;
