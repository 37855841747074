import Parse from 'parse';
import { multiEntityLoading } from '@/store/mixins/loading-states';

const DispoPlanerModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    vacationForAllUsers: null,
    workingGroups: [],
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setWorkingGroups(state, wg) {
      state.workingGroups = wg;
    },
    setVacationForAllUsers(state, vacationForAllUsers) {
      state.vacationForAllUsers = vacationForAllUsers;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadWorkingGroups({ commit, dispatch }) {
      try {
        const query = new Parse.Query('WorkingGroup');
        query.include('users');
        query.limit(3000);
        query.addAscending('name');

        const results = await dispatch('handleLoadingState', {
          entity: 'workingGroups',
          promise: query.find(),
        });
        commit('setWorkingGroups', results);
      } catch (e) {
        commit('setWorkingGroups', []);
      }
    },
    async loadVacationsForAllUsers({ commit }, payload) {
      try {
        const vac = await Parse.Cloud.run(
          'loadVacationsForAllUsers1',
          { month: payload.month, year: payload.year },
          {}
        );
        commit('setVacationForAllUsers', vac);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    workingGroups: (state) => state.workingGroups,
    vacationForAllUsers: (state) => state.vacationForAllUsers,
  },
};
export default DispoPlanerModule;
