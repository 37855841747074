import Parse from 'parse';

const WorkingHoursModule = {
  namespaced: true,
  state: {
    workingHours: null,
  },
  mutations: {
    setWorkingHours(state, workingHours) {
      state.workingHours = workingHours;
    },
  },
  actions: {
    async loadWorkingHours({ commit }, apiString) {
      try {
        const wh = await Parse.Cloud.run('getWorkingHours', { apiString }, {});
        commit('setWorkingHours', wh);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    workingHours: (state) => state.workingHours,
  },
};
export default WorkingHoursModule;
