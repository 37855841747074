import Parse from 'parse';

const ApprovedVacationRequestsModule = {
  namespaced: true,
  state: {
    vacationRequests: [],
    lastFetched: null,
    workingGroupsUserNumber: {},
  },
  mutations: {
    SET_VACATION_REQUESTS(state, requests) {
      state.vacationRequests = requests;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
    setWorkingGroupNumber(state, workingGroupInfo) {
      state.workingGroupsUserNumber[workingGroupInfo.name] = workingGroupInfo.data;
    },
  },
  actions: {
    async loadApprovedVacationRequests({ commit }, allStatus = false) {
      try {
        const response = await Parse.Cloud.run('getVacationOfGroupsOfUser', { allStatus });
        commit('SET_VACATION_REQUESTS', response);
        const workingGroups = Object.keys(response);
        for (let i = 0; i < workingGroups.length; i++) {
          const usersNumber = await Parse.Cloud.run('getNumberOfUsersInWorkingGroup', { name: workingGroups[i] }, {});
          const o = {};
          o.data = usersNumber;
          o.name = workingGroups[i];
          commit('setWorkingGroupNumber', o);
        }
        commit('setLastFetch', new Date());
      } catch (e) {
        commit('SET_VACATION_REQUESTS', null);
      }
    },
  },
  getters: {
    getApprovedVacationRequests: (state) => state.vacationRequests,
    lastFetched: (state) => state.lastFetched,
    workingGroupsUserNumber: (state) => state.workingGroupsUserNumber,
  },
};

export default ApprovedVacationRequestsModule;
