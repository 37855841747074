import Parse from 'parse';

const AdminCalendarModule = {
  namespaced: true,
  state: {
    events: {},
    lastData: null,
    eventsForADay: {},
  },
  mutations: {
    addEvents(state, { response, key }) {
      state.events[key] = response;
      //Vue.set(state.events, key, response);
    },
    resetEvents(state) {
      state.events = {};
    },
    setLastData(state, lastData) {
      state.lastData = lastData;
    },
    setEventsForADay(state, dd) {
      state.eventsForADay = dd;
    },
  },
  actions: {
    async loadEvents({ commit, state }, data) {
      commit('setLastData', data);
      console.log(data)
      const key = `${data.fromYear}-${data.fromMonth}-${data.toYear}-${data.toMonth}`;

      if (state.events[key]) return;
      try {
        let response = await Parse.Cloud.run('getAdminCalendarEvents', data, {});
        response = response.map((el) => {
          el.dates = new Date(el.dates);
          return el;
        });
        console.log(response)
        commit('addEvents', { response, key });
      } catch (e) {
        console.log(e);
      }
    },
    async reloadEvents({ state, commit, dispatch }) {
      commit('resetEvents');
      await dispatch('loadEvents', state.lastData);
    },
    async loadEventsForADay({ state, commit, dispatch }, date) {
      try {
        const response = await Parse.Cloud.run('getCalendarEventsForDate', { date: date.toDateString() }, {});
        commit('setEventsForADay', response);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    async loadCalendarEventsExport({ state, commit }, payload) {
      const data = await Parse.Cloud.run(
        'loadCalendarEventsExport',
        { year: payload.year, month: payload.month, calendarConfig: payload.calendarConfig },
        {}
      );
      return data;
    },
  },
  getters: {
    events: (state) => Object.values(state.events).flat(),
    eventsForADay: (state) => state.eventsForADay,
  },
};

export default AdminCalendarModule;
