import { toastController } from '@ionic/vue';

export const show = async (text) => {
  const toast = await toastController.create({
    message: text,
    duration: 3000,
    position: 'top',
    color: 'success',
  });
  return toast.present();
};

export const error = async (text) => {
  const toast = await toastController.create({
    message: text,
    duration: 3000,
    position: 'top',
    color: 'danger',
  });
  return toast.present();
};

const ionicToastController = {
  show,
  error,
};

export default ionicToastController;
