import Parse from 'parse';
import { multiEntityLoading } from '@/store/mixins/loading-states';

const DailyReportModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    reports: [],
    reportsInRange: [],
    lastFetched: null,
    disabledDailyReportSlots: {},
    dispoMessageBadges: {},
    dispoMessageDates: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    addReport(state, report) {
      state.reports.push(report);
    },
    setReports(state, reports) {
      state.reports = reports;
    },
    setReportsInRange(state, rr) {
      state.reportsInRange = rr;
    },
    setLastFetch(state, date) {
      state.lastFetched = date;
    },
    removeReport(state, report) {
      state.reports.splice(state.reports.indexOf(report), 1);
    },
    setDailyReportDisabledSlots(state, s) {
      state.disabledDailyReportSlots = s;
    },
    setDispoMessageBadges(state, m) {
      state.dispoMessageBadges = m;
    },
    setDispoDates(state, d) {
      state.dispoMessageDates = d;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadReports({ commit, dispatch }, { year, month }) {
      try {
        const query = new Parse.Query('DailyReport');
        query.greaterThan('day', new Date(year, month));
        query.lessThanOrEqualTo('day', new Date(year, month + 1, 1));
        query.include('user');
        query.limit(3000);

        const results = await dispatch('handleLoadingState', {
          entity: 'loadReports',
          promise: query.find(),
        });
        commit('setReports', results);
        commit('setLastFetch', new Date());
      } catch (e) {
        commit('setReports', []);
      }
    },
    async loadReportsInRange({ commit, dispatch }, { startDate, endDate }) {
      try {
        const query = new Parse.Query('DailyReport');
        query.greaterThanOrEqualTo(
          'day',
          new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1)
        );
        query.lessThanOrEqualTo('day', endDate);
        query.include('user');
        query.limit(3000);

        const results = await dispatch('handleLoadingState', {
          entity: 'loadReportsInRange',
          promise: query.find(),
        });

        console.log(results);
        commit('setReportsInRange', results);
      } catch (e) {
        commit('setReportsInRange', []);
      }
    },
    async addReport({ commit }, { user, day }) {
      let report = new Parse.Object('DailyReport');
      report.set('day', new Date(Date.UTC(day.year(), day.month(), day.date())));
      report.set('user', user);
      report.set('active', true);
      report.set('log', [
        {
          user: Parse.User.current().id,
          name: Parse.User.current().get('firstname') + ' ' + Parse.User.current().get('lastname'),
          status: 'check',
          day: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
        },
      ]);
      try {
        report = await report.save();
        commit('addReport', report);
        return report;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async toggleReportStatus({}, report) {
      let s = 'uncheck';
      if (!report.get('active')) {
        s = 'check';
      }
      let a = [];
      if (report.get('log')) {
        a = report.get('log');
        a.push({
          name: Parse.User.current().get('firstname') + ' ' + Parse.User.current().get('lastname'),
          user: Parse.User.current().id,
          status: s,
          day: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
        });
      } else {
        a = [
          {
            user: Parse.User.current().id,
            status: s,
          },
        ];
      }
      report.set('log', a);
      report.set('active', !report.get('active'));
      try {
        await report.save();
      } catch (e) {
        console.log(e);
      }
    },
    async loadDisabledDailyReportSlots({ commit }, showVacationStatus = false) {
      try {
        const dd = await Parse.Cloud.run('getDisabledDailyReportSlots', { showVacationStatus }, {});
        commit('setDailyReportDisabledSlots', dd);
      } catch (e) {
        console.log(e);
      }
    },
    async loadMessageBadges({ commit, state }, { startDate, endDate = null }) {
      let param = { startDate, endDate };
      const d = new Date(endDate);
      param.endDate = new Date(d.setTime(d.getTime() + 5 * 60 * 60 * 1000));

      if (!startDate && !endDate) {
        param = state.dispoMessageDates;
      }

      if (startDate) commit('setDispoDates', param);

      try {
        const dd = await Parse.Cloud.run('loadDispoMessageBadges', param, {});
        commit('setDispoMessageBadges', dd);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    reports: (state) => state.reports,
    reportsInRange: (state) => state.reportsInRange,
    disabledDailyReportSlots: (state) => state.disabledDailyReportSlots,
    dispoMessageBadges: (state) => state.dispoMessageBadges,
  },
};
export default DailyReportModule;
