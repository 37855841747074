import Parse from 'parse';

const AuthModule = {
  namespaced: true,
  state: {
    currentUser: null,
    roles: [],
    freigeberGroups: null,
    parseData: null,
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setFreigeberGroups(state, groups) {
      state.freigeberGroups = groups;
    },
    setParseData(state, parseData) {
      state.parseData = parseData;
    }
  },
  actions: {
    async loadUser({ dispatch, commit }) {
      try {
        await Parse.User.current().fetch(null);
        commit('setUser', Parse.User.current());
        await dispatch('loadRoles');
      } catch (e) {
        console.log(e);
        if (e.code === 209) {
          dispatch('logout');
        }
        commit('setUser', null);
      }
    },
    async loadRoles({ commit }) {
      try {
        const roles = await Parse.Cloud.run('getRole', null, null);
        commit('setRoles', roles);
        return roles;
      } catch (e) {
        console.log(e)
        commit('setRoles', []);
        return [];
      }
    },
    async loadFreigeberGroups({ commit }) {
      const response = await Parse.Cloud.run('getFreigeberGroups', {}, {});
      commit('setFreigeberGroups', response);
    },
    async loadTenantData({ commit }, workspace) {
      let parseData;
      try {
        const response = await fetch(`https://tenants.ekkko.de/tenant/${workspace}`);
        parseData = await response.json();
        if (parseData.appId) {
          Parse.initialize(parseData.appId, parseData.jsKey);
          Parse.serverURL = parseData.serverUrl;
          // Vue.prototype.$parse = Parse;
          localStorage.setItem('tenant-data-app-id', parseData.appId);
          localStorage.setItem('tenant-data-js-key', parseData.jsKey);
          localStorage.setItem('tenant-data-server-url', parseData.serverUrl);
          localStorage.setItem('tenant-data-features', parseData.features);
          localStorage.setItem('tenant-data', parseData);
          commit('setParseData', parseData);
        }
      } catch (e) {
        console.log(e);
        localStorage.removeItem('tenant-data-app-id');
        localStorage.removeItem('tenant-data-js-key');
        localStorage.removeItem('tenant-data-server-url');
        localStorage.removeItem('tenant-data-features');
        localStorage.removeItem('tenant-data');
        throw new Error('tenant_not_found');
      }
    },
    async login({ commit, dispatch }, { email, password }) {
      try {
        const user = await Parse.User.logIn(email.toLowerCase(), password, null);
        await dispatch('posts/loadPosts', null, { root: true });
        await dispatch('posts/loadPinnedPosts', null, { root: true });
        await dispatch('loadRoles');
        await dispatch('feessbook-groups/loadfeessbookGroups', null, { root: true });
        await dispatch('feessbook-groups/loadMyFeessbookGroups', null, { root: true });
        await dispatch('parse-config/loadConfig', null, { root: true });
        await dispatch('active-message/loadActiveMessagePosts', null, { root: true });
        await dispatch('vacation-request/loadRequestsToConfirm', null, { root: true });
        await dispatch('calendar-event-entries/loadCalendarEvents', null, { root: true });
        commit('setUser', user);
        return user;
      } catch (e) {
        console.log(e);
        throw new Error('parseserver_error_101');
      }
    },
    async logout({ commit }) {
      await Parse.User.logOut();
      commit('set', null);
      localStorage.removeItem('tenant-data-app-id');
      localStorage.removeItem('tenant-data-js-key');
      localStorage.removeItem('tenant-data-server-url');
      localStorage.removeItem('tenant-data-features');
      window.location.reload();
    },
  },
  getters: {
    currentUser: (state) => state.currentUser,
    freigeberGroups: (state) => state.freigeberGroups,
    roles: (state) => state.roles,
    isEditor: (state) => state.roles.includes('Redakteur') || state.roles.includes('Admin'),
    canAccessAdmin: (state) =>
      state.roles.includes('Personaler') ||
      state.roles.includes('Dispo') ||
      state.roles.includes('Admin') ||
      state.roles.includes('Leistungserfasser'),
    canAccessDailyReport: (state) => state.roles.includes('Admin') || state.roles.includes('Leistungserfasser'),
    canAccessAdminCalendar: (state) => state.roles.includes('Personaler') || state.roles.includes('Admin'),
    isAdmin: (state) => state.roles.includes('Admin'),
    canAccessDispo: (state) => state.roles.includes('Dispo') || state.roles.includes('Admin'),
    isExtern: (state) => state.roles.includes('Extern'),
    isPersonaler: (state) => state.roles.includes('Personaler') || state.roles.includes('Admin'),
  },
};
export default AuthModule;
