const SetFocusModule = {
  namespaced: true,
  state: {
    setFocus: false,
  },
  mutations: {
    SET_FOCUS(state) {
      state.setFocus = true;
    },
    UNSET_FOCUS(state) {
      state.setFocus = false;
    },
  },
  actions: {
    setFocus({ commit }) {
      commit('SET_FOCUS');
    },
    unsetFocus({ commit }) {
      commit('UNSET_FOCUS');
    },
  },
  getters: {
    setFocus: (state) => state.setFocus,
  },
};
export default SetFocusModule;
