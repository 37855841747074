import Parse from 'parse';
const TagModule = {
  namespaced: true,
  state: {
    tags: []
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    },
  },
  actions: {
    async loadTag({ commit }) {
      try {
        const tagQuery = await new Parse.Query('Tag').find();
        commit('setTags',tagQuery);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    tags: (state) => state.tags,
  }
};
export default TagModule;
