import Parse from 'parse';

const ActiveMessageModule = {
  namespaced: true,
  state: {
    activeMessage: [],
    confirmedBy: [],
    confirmedMessage: null,
  },
  mutations: {
    deleteActiveMessage(state, postId) {
      if (state.activeMessage.length > 0) {
        const i = state.activeMessage.findIndex((el) => el.id === postId);
        state.activeMessage.splice(i, 1);
      }
    },
    setConfirmedBy(state, confirmedBy) {
      state.confirmedBy = confirmedBy;
    },
    setActiveMessage(state, activeMessage) {
      state.activeMessage = activeMessage;
    },
    setConfirmedMessage(state, confirmedMessage) {
      state.confirmedMessage = confirmedMessage;
    },
  },
  actions: {
    async loadActiveMessagePosts({ commit }) {
      try {
        const resultArr = await Parse.Cloud.run('loadActiveMessagePosts', {}, {});
        commit('setActiveMessage', resultArr);
      } catch (e) {
        console.log(e);
      }
    },
    async addConfirmedActiveMessage({ commit }, postId) {
      try {
        await Parse.Cloud.run('addConfirmedActiveMessage', { postId }, {});
        commit('deleteActiveMessage', postId);
      } catch (e) {
        console.log(e);
      }
    },
    async loadConfirmedActiveMessageForPost({ commit }, postId) {
      try {
        const confirmedBy = await Parse.Cloud.run('loadConfirmedActiveMessageForPost', { postId }, {});
        commit('setConfirmedBy', confirmedBy);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    confirmedBy: (state) => state.confirmedBy,
    activeMessage: (state) => state.activeMessage,
    confirmedMessage: (state) => state.confirmedMessage,
  },
};
export default ActiveMessageModule;
